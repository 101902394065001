const JobCardSkeleton = () => {
  return (
    <div className="card" aria-hidden="true">
      <div className="card-body">
        <h5 className="card-title placeholder-glow" style={{ width: "100px" }}>
          <span className="placeholder placeholder-lg col-12"></span>
          <span className="placeholder col-6"></span>
        </h5>
        <p className="card-text placeholder-glow">
          <span className="placeholder placeholder-lg col-8"></span>
          <span className="placeholder col-6"></span>
          <span className="placeholder col-7"></span>
          <span className="placeholder col-10 m-3"></span>
        </p>
        <span
          className="btn disabled placeholder col-6"
          style={{ background: "var(--primary)" }}
        ></span>
      </div>
    </div>
  );
};
const PricingSkeleton = () => {
  return (
    <div className="card" aria-hidden="true">
      <div className="card-body">
        <h5 className="card-title placeholder-glow">
          <span className="placeholder placeholder-lg col-12"></span>
          <span className="placeholder col-6"></span>
        </h5>
        <p className="card-text placeholder-glow mt-4">
          <span className="placeholder col-8"></span>
          <span className="placeholder col-6"></span>
          <span className="placeholder col-7"></span>
          <span className="placeholder col-8"></span>
          <span className="placeholder col-6"></span>
          <span className="placeholder col-7"></span>
          <span className="placeholder col-10"></span>
        </p>
        <span
          className="btn disabled placeholder col-6 mt-3"
          style={{ background: "var(--primary)" }}
        ></span>
      </div>
    </div>
  );
};
const PaymentSkeleton = () => {
  return (
    <div className="card" aria-hidden="true">
      <div className="card-body">
        <h5 className="card-title placeholder-glow">
          <span className="placeholder placeholder-lg col-12"></span>
          <span className="placeholder col-6"></span>
        </h5>
        <p className="card-text placeholder-glow mt-4">
          <span className="placeholder col-8"></span>
          <span className="placeholder col-6"></span>
          <span className="placeholder col-7"></span>
          <span className="placeholder col-8"></span>
          <span className="placeholder col-6"></span>
          <span className="placeholder col-7"></span>
          <span className="placeholder col-10"></span>
        </p>
      </div>
    </div>
  );
};
const JobDetailSkeleton = () => {
  return (
    <>
      <div className="col-12">
        <div className="card shadow mt-5">
          <div className="card-body">
            <div className="job-heading">
              <h3 className="placeholder-glow" style={{ width: "200px" }}>
                <span className="placeholder placeholder-lg col-12"></span>
              </h3>
              <div className="job-info gap-3">
                <p className="placeholder-glow" style={{ width: "100px" }}>
                  <span className="placeholder placeholder-lg col-12"></span>
                </p>
                <p className="placeholder-glow">
                  <span className="placeholder placeholder-lg col-12"></span>
                </p>
                <p className="placeholder-glow">
                  <span className="placeholder placeholder-lg col-12"></span>
                </p>
              </div>
            </div>
            <div
              className="placeholder-glow"
              style={{ width: "100px", height: "20px" }}
            ></div>
          </div>
        </div>
        <div className="card shadow mt-1 mb-4">
          <div className="card-body">
            <div className="job-description">
              <h5 className="title"></h5>
              <div className="dz-post-text">
                <div
                  className="placeholder-glow"
                  style={{ width: "100%", height: "200px" }}
                >
                  <p className="placeholder-glow">
                    <span className="placeholder placeholder-lg col-12"></span>
                  </p>
                  <p className="placeholder-glow">
                    <span className="placeholder placeholder-lg col-12"></span>
                  </p>
                  <p className="placeholder-glow">
                    <span className="placeholder placeholder-lg col-12"></span>
                  </p>
                  <p className="placeholder-glow">
                    <span className="placeholder placeholder-lg col-12"></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="job-social-profile">
              <h5 className="title"></h5>
              <div className="dz-share-post">
                <div className="dz-social-icon dark">
                  <p className="placeholder-glow" style={{ width: "200px" }}>
                    <span className="placeholder placeholder-lg col-12"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BlogCardSkeleton = () => {
  return (
    <div className="card" aria-hidden="true">
      <div className="card-body">
        <h5 className="card-title placeholder-glow" style={{ width: "100%" }}>
          <span className="placeholder placeholder-lg col-12"></span>
          <span className="placeholder col-6"></span>
        </h5>
        <p className="card-text placeholder-glow">
          <span className="placeholder placeholder-lg col-8"></span>
          <span className="placeholder col-6"></span>
          <span className="placeholder col-7"></span>
          <span className="placeholder col-10 m-3"></span>
        </p>
        <span
          className="btn disabled placeholder col-6"
          style={{ background: "var(--primary)" }}
        ></span>
      </div>
    </div>
  );
};

const SectionSkeleton = () => {
  return (
    <div className="container" aria-hidden="true">
      <span
        className="placeholder placeholder-lg col-12"
        style={{ height: "250px" }}
      ></span>
    </div>
  );
};

export {
  JobCardSkeleton,
  PricingSkeleton,
  JobDetailSkeleton,
  BlogCardSkeleton,
  PaymentSkeleton,
  SectionSkeleton,
};
