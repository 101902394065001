import { Link, useNavigate } from "react-router-dom";
import "../app/Footer.css";
import { ROUTES, listBlog } from "../../helpers/RouteHelper";
import { FOOTER } from "../../config/config";
import { TENANT_CONFIG } from "../../config/tenant";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const nav = useNavigate();
  const { t } = useTranslation();

  function handleSubmit(e) {
    e.preventDefault();
    nav("#");
  }

  return (
    <footer className="site-footer style-1 position-relative">
      <div className={`footer-top bg-light`}>
        <div className="container">
          <div className="row p-50">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div
                className="widget wow fadeInUp text-center text-lg-start"
                data-wow-delay="1.4s"
              >
                <div className="footer-logo">
                  <Link to={"/"} className="logo-dark">
                    <img
                      src={TENANT_CONFIG.LogoSquare}
                      alt={TENANT_CONFIG.AppTitle + " Logo"}
                    />
                  </Link>
                </div>
                <p>{t("#home.banner.title")}</p>
                <div className="widget_getintuch ">
                  <ul className="">
                    <i className=" icon fa-regular fa-comments "></i>
                    <a
                      style={{ color: "var(--primary)" }}
                      href={`mailto:${TENANT_CONFIG.AppEmail}`}
                    >
                      {TENANT_CONFIG.AppEmail}
                    </a>

                    <li className="text-center "></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row">
                {listBlog.map((item, index) => (
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6" key={index}>
                    <div className="widget widget_links">
                      <h4 className="footer-title">{item.maintitle}</h4>
                      {item.list.map((data, ind) => (
                        <ul key={ind}>
                          <li>
                            <Link to={data.link}>{data.title}</Link>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* NEWS LETTER */}
            {FOOTER.showNewsLetter && (
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="widget wow fadeInUp" data-wow-delay="2.2s">
                  <h4 className="footer-title">Newsletter</h4>
                  <p>Sign up for Job alerts</p>
                  <form
                    className="dzSubscribe ft-subscribe mb-4"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className="dzSubscribeMsg"></div>
                    <div className="input-group mb-0">
                      <input
                        name="dzEmail"
                        required="required"
                        type="email"
                        style={{ padding: "10px" }}
                        className="form-control form-control-lg"
                        placeholder="Your Email Address"
                      />
                      <button
                        name="submit"
                        value="Submit"
                        type="submit"
                        className="btn style-1  button-animated"
                        style={{
                          background: "var(--primary)",
                          color: "var(--primary-contrast)",
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="footer-bottom text-md-center text-md-center bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="footer-inner text-center ">
                <p
                  className="copyright-text wow fadeInUp"
                  data-wow-delay="2.4s"
                >
                  {" "}
                  {`©  Copyright: `}
                  <Link style={{ color: "var(--primary)" }} to={ROUTES.HOME}>
                    {TENANT_CONFIG.AppHost}
                  </Link>{" "}
                  All rights Reserved
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="footer-inner text-center ">
                <Link to={"http://jobportalpaymentcan.ca/"} className="logo-dark" target="_blank">
                  <img
                    width={"150px"}
                    src="/jobportalpaymentcan/Logo_Square.png"
                    alt={TENANT_CONFIG.AppTitle + " Logo"}
                  />
                </Link>
                <p
                  className="copyright-text wow fadeInUp"
                  data-wow-delay="2.4s"
                >
                  All communication and payments will be made with our domain{" "}
                  <a
                    target="_blank"
                    href="http://jobportalpaymentcan.ca/"
                    rel="noreferrer"
                  >
                    jobportalpaymentcan.ca
                  </a>
                </p>
              </div>
            </div>
            {/* <div className="col-12">
              <div className="footer-inner text-center ">
                <p
                  className="copyright-text wow fadeInUp"
                  data-wow-delay="2.4s"
                >
                  Developed by{" "}
                  <a
                    target="_blank"
                    href="http://linearalgo.com/"
                    rel="noreferrer"
                  >
                    Linear Algo Solutions Inc.
                  </a>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
