/* eslint-disable react/prop-types */
import { useEffect, useReducer, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { menuItem, ROUTES } from "../../../src/helpers/RouteHelper";
import { connect, useDispatch } from "react-redux";
import { clearUser } from "../../store/reducers/appSlice/appSlice";
import "../app/Header.css";
import { IsBackendSection, IsFrontendSection } from "../../config/config";
import { TENANT_CONFIG } from "../../config/tenant";

function NavMenuToggle() {
  setTimeout(() => {
    let toggleBtn = document.getElementById("navbutton");
    let sidebarOpen = document.getElementById("navbarNavDropdown");
    toggleBtn.classList.toggle("open");
    sidebarOpen.classList.toggle("show");
  }, 200);
}

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  activeSubmenu: "",
};

const defaultProps = {};

const propTypes = {};

const mapStateToProps = ({ app }) => ({
  isAuthenticated: app?.isAuthenticated,
  IsBackendUser: app?.User?.IsBackendUser,
});

const Header = connect(
  mapStateToProps,
  null
)(({ isAuthenticated, style }) => {
  const location = useLocation();
  const [state, setState] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  const [headerFix, setHeaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setHeaderFix(window.scrollY > 50);
    });
  }, []);

  return (
    <>
      <header className={`site-header mo-left header ${style}`}>
        <div
          className={`sticky-header main-bar-wraper navbar-expand-lg ${
            headerFix ? "is-fixed" : ""
          }`}
        >
          <div className="main-bar clearfix ">
            <div className="container clearfix">
              <div className="logo-header" style={{ zIndex: 100 }}>
                <Link to={"/"} className="logo-dark">
                  <img
                    src={TENANT_CONFIG.Logo}
                    alt={TENANT_CONFIG.AppTitle + " Logo"}
                  />
                </Link>
              </div>

              <button
                className="navbar-toggler collapsed navicon justify-content-end"
                type="button"
                id="navbutton"
                onClick={() => NavMenuToggle()}
              >
                &nbsp;
                <i
                  className="fa fa-list"
                  style={{ color: "var(--primary-contrast)" }}
                />
              </button>

              <div className="extra-nav">
                {IsFrontendSection() && (
                  <Link
                    to={ROUTES.JOBS}
                    style={{ backgroundColor: "var(--secondary)" }}
                    className="btn btn-md text-light button-animated"
                  >
                    Find a Job
                  </Link>
                )}
                {isAuthenticated && (
                  <div className="ml-3 extra-cell">
                    <Link
                      onClick={() => dispatch(clearUser())}
                      className="btn btn-md bg-danger text-light"
                    >
                      Logout
                    </Link>
                  </div>
                )}
              </div>

              {IsBackendSection() && (
                <div className="extra-nav">
                  <div className="extra-cell">
                    <Link
                      style={{ backgroundColor: "var(--secondary)" }}
                      className={"btn btn-md text-light button-animated"}
                      to={ROUTES.JOBS_POST}
                    >
                      Post a Job
                    </Link>
                  </div>
                </div>
              )}
              {/* <div className="dz-quik-search on">
                <input
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Keyword ..."
                />
                <span id="quik-search-remove">
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div> */}
              <div
                className="header-nav navbar-collapse collapse justify-content-end"
                id="navbarNavDropdown"
              >
                <div className="logo-header"></div>
                <ul className="nav navbar-nav navbar navbar-left">
                  {menuItem.map((item, index) => {
                    if (item.backendUserMenu && !IsBackendSection()) {
                      return null;
                    }
                    if (item.isProtected && !isAuthenticated) {
                      return null;
                    }
                    if(item.isPackageNeeded && !TENANT_CONFIG.EnablePackage) {
                      return null;
                    }
                    let menuClass = item.dropmenu;
                    if (menuClass === "sub-menu-down") {
                      return (
                        <li
                          key={index}
                          className={`sub-menu-down  ${
                            state.activeSubmenu === item.title ? "open" : ""
                          } ${location.pathname === item.to ? "active" : ""}`}
                        >
                          <Link
                            to={"#"}
                            onClick={() => handleSubmenuActive(item.title)}
                          >
                            {item.title}
                          </Link>
                          <ul className="sub-menu">
                            {item?.content?.map((data, ind) => {
                              if (data.backendUserMenu && !IsBackendSection()) {
                                return null;
                              }
                              if (data.isProtected && !isAuthenticated) {
                                return null;
                              }
                              return (
                                <li key={ind}>
                                  <Link
                                    onClick={() => {
                                      NavMenuToggle();
                                    }}
                                    to={data.to}
                                  >
                                    {data.subtitle}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={index}
                          className={
                            location.pathname === item.to ? "active" : ""
                          }
                        >
                          <Link
                            to={item.to}
                            onClick={() => {
                              NavMenuToggle();
                            }}
                          >
                            {item.title}
                          </Link>
                        </li>
                      );
                    }
                  })}
                  {IsBackendSection() && (
                    <div className="has-mega-menu d-block d-md-none mt-4">
                      <div className="extra-cell">
                        <Link
                          style={{ backgroundColor: "var(--secondary)" }}
                          className={"btn btn-md text-light button-animated"}
                          to={ROUTES.JOBS_POST}
                        >
                          Post a Job
                        </Link>
                      </div>
                    </div>
                  )}
                  {IsFrontendSection() && (
                    <div className="has-mega-menu d-block d-md-none mt-4">
                      <div className="extra-cell">
                        <Link
                          style={{ backgroundColor: "var(--secondary)" }}
                          className={"btn btn-md text-light button-animated"}
                          to={ROUTES.JOBS}
                        >
                          Find a job
                        </Link>
                      </div>
                    </div>
                  )}
                  {isAuthenticated && (
                    <div className="has-mega-menu d-block d-md-none mt-4">
                      <div className="extra-cell">
                        <Link
                          onClick={() => dispatch(clearUser())}
                          className="btn btn-md bg-danger text-light"
                        >
                          Logout
                        </Link>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
});

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
