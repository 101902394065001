export const APP_TYPE_CODES = {
  NEW_COMERS: "newcomersjobshub",
  INDIGENOUS: "indigenouscareer",
  VETERANS: "veterans-seniorsjobs",
  YOUTH_CAREER: "youthcareer",
  JOB_WOB: "jobwob",
  ALL_NEW_JOBS: "allnewjobs",
};

export const MASTER_CODES = {
  JOB_TYPE: "JOB_TYPE",
  JOB_STATUS: "JOB_STATUS",
  EMPLOYER_TYPE: "EMPLOYER_TYPE",
  WORK_LOCATION: "WORK_LOCATION",
  JOB_CATEGORY: "JOB_CATEGORY",
  TERRITORY: "TERRITORY",
  CURRENCY: "CURRENCY",
  EDUCATION: "EDUCATION",
  LANGUAGE: "LANGUAGE",
  DURATION_TYPE: "DURATION_TYPE",
  COUPON_CODE: "COUPON_CODE",
  REMOTE_CONFIG: "REMOTE_CONFIG",
};

export const MASTER_DATA_CODES = {
  EMPLOYER_TYPE: { PRIVATE: "PRVT" },
};

export const REMOTE_APP_CONFIG = {
  STRIPE_BILLING_PORTAL: "STRIPE_BILLING_PORTAL",
};

export const LOGIN_TYPE = {
  EMPLOYER: "employer",
  CANDIDATE: "candidate",
};
