/* eslint-disable no-unused-vars */
import { Provider, connect, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store/store";
import { Suspense, useEffect } from "react";
import BundleLoader from "./pages/app/BundleLoader";
import { ThemeProvider } from "react-bootstrap";
import i18next from "i18next";
import { ReactQueryDevtools } from "react-query/devtools";
import ErrorBoundary from "./pages/app/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "react-query";
import PropTypes from "prop-types";
// Initializes i18next library
import "./i18n/i18next";
import "./App.css";

// import "./ThemeOne.css";
import MainRouter from "./MainRouter";
import { useGetMasters } from "./services/useMaster";
import { setMasters } from "./store/reducers/masterSlice/masterSlice";
import { ToastContainer } from "react-toastify";
import { useTokenVerify } from "./services/useAuth";
import { clearUser, setUser } from "./store/reducers/appSlice/appSlice";
import { toastError } from "./helpers/CommonHelper";
import Header from "./pages/app/Header";
import Footer from "./pages/app/Footer";
import AuthData from "./AuthData";
import { APP_CODE } from "./config/env";

// Configure react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const defaultProps = {};

const propTypes = {};

const mapStateToProps = ({ app }) => ({
  AccessToken: app?.AccessToken,
  isAuthenticated: app?.isAuthenticated,
  IsBackendUser: app?.User?.IsBackendUser,
  IsEmailVerified: app?.User?.IsEmailVerified,
});

const App = connect(
  mapStateToProps,
  null
)(({ AccessToken, isAuthenticated, IsEmailVerified }) => {
  const { data: masterData, isLoading: isLoadingMaster } = useGetMasters();
  const { mutate, isLoading, error } = useTokenVerify();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.remove(APP_CODE);
    document.body.classList.add(APP_CODE);
  }, []);

  useEffect(() => {
    if (masterData) {
      dispatch(setMasters(masterData));
    }
  }, [dispatch, masterData]);

  useEffect(() => {
    if (AccessToken) {
      mutate(
        { token: AccessToken },
        {
          onSuccess: async (response) => {
            const data = await response.json();
            if ([200, 201].includes(response?.status)) {
              dispatch(setUser(data));
            } else {
              dispatch(clearUser());
              toastError(data?.detail || "Session expired, Login again!");
            }
          },
          onError: (error) => {
            dispatch(clearUser());
            toastError("Session expired, Login again!");
          },
        }
      );
    }
  }, [AccessToken, dispatch, mutate]);

  return (
    <>
      {(isLoading || isLoadingMaster) && <div className="loading" />}
      {isAuthenticated && <AuthData />}
      {/* Toast container */}
      <ToastContainer />
      {/* NAVIGATION */}
      {/* <Navigation /> */}
      <Header style="header-transparent" />
      {/* PAGE BODY */}
      <MainRouter />
      {/* FOOTER */}
      <Footer />
    </>
  );
});
App.propTypes = propTypes;
App.defaultProps = defaultProps;
export default App;

export const ContextProvider = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider
          dir={i18next.dir()}
          breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
          minBreakpoint="xxs"
        >
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              {/* ROUTER */}
              <Suspense fallback={<BundleLoader />}>
                <App />
              </Suspense>
            </PersistGate>
          </Provider>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
