import { Link } from "react-router-dom";
import { ROUTES } from "../../../helpers/RouteHelper";
import { TENANT_CONFIG } from "../../../config/tenant";

const ContactUsBanner = () => {
  return (
    <>
      <section className="pt-0">
        <div className="container">
          <div className="text-white p-4 p-sm-5 rounded-3" style={{ background: 'var(--primary)' }}>
            <div className="row g-3 g-xl-4 align-items-center">
              <div className="col-xl-8">
                {/* Title */}
                <div className="d-sm-flex align-items-center mb-2">
                  <h3 className="mb-2 mb-sm-0">Still, have a question?</h3>
                </div>
                <p className="mb-0">
                  <strong>{TENANT_CONFIG.AppTitle}</strong> is here to help you make a remarkable
                  impact and build a brighter future in your new home.
                </p>
              </div>
              {/* Content and input */}
              <div className="col-xl-4 text-xl-end">
                <Link to={ROUTES.CONTACT_US} className="btn mb-0" style={{ background: 'var(--secondary)', color:'var(--secondary-contrast)' }}>
                  Contact us
                  <i className="bi bi-arrow-right-circle ms-2" />
                </Link>
              </div>
            </div>{" "}
            {/* Row END */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsBanner;
