import axios from "axios";
import { useQuery } from "react-query";
import { API_URL } from "../config/env";

export const useGetTenants = (filters='') => {
  return useQuery(
    ["Tenant-list"],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/tenant?${filters}`,
        params: {},
      }),
    { select: (response) => response.data.results },
  );
};
