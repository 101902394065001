import * as Yup from "yup";
export const JOB_POST_FORM = "jobPost";
export const JOB_VALIDATION_SCHEMA = Yup.object().shape({
  EmployerType: Yup.string().required("Required"),
  Territory: Yup.string().nullable(),
  WorkLocation: Yup.string().required("Required"),
  JobTypes: Yup.array()
    .required("Required")
    .min(1, "Pick at least 1 tags")
    .of(Yup.string().required()),
  JobCategories: Yup.array()
    .required("Required")
    .min(1, "Pick at least 1 tags")
    .of(Yup.string().required()),

  JobTitle: Yup.string()
    .max(200, "Must be 200 characters or less")
    .required("Required"),
  Location: Yup.string().max(100, "Must be 100 characters or less"),
  Description: Yup.string().required("Required"),
  Wage: Yup.number().required("Required").min(0, "It should be greater than 0"),
  WageCurrency: Yup.string().required("Required"),
  WageDuration: Yup.string().required("Required"),
  EducationalBackground: Yup.string().required("Required"),

  AvailablePositionsNo: Yup.number()
    .min(1, "Must be greater than 0")
    .max(50, "Must be less than 50"),
  // Education: Yup.string().nullable(),
  Languages: Yup.string().nullable(),
  Skills: Yup.string().nullable(),

  ApplicationDeadline: Yup.string().nullable(),
  ApplicationEmailOrURL: Yup.string()
    .email("Must be an valid email")
    .max(500, "Must be 500 characters or less"),
  // .required("Required"),

  ClosestIntersection: Yup.string().nullable(),
  CompanyName: Yup.string()
    .max(500, "Must be 50 characters or less")
    .required("Required"),
  CompanyDescription: Yup.string().nullable(),
  CompanyAddress: Yup.string()
    .max(500, "Must be 50 characters or less")
    .nullable(),
  CompanyPostalCode: Yup.string()
    .max(50, "Must be 50 characters or less")
    .nullable(),
  Website: Yup.string().nullable(),
  Logo: Yup.mixed()
    .test("fileType", "Please provide a supported file type", (file) => {
      if (!file) return true; // Empty field is allowed
      const validExtensions = ["png", "jpg", "jpeg"];
      const extension = file.name.split(".").pop().toLowerCase();
      return validExtensions.includes(extension);
    })
    .test(
      "fileSize",
      `File too big, can't exceed ${1 * 1024 * 1024} bytes`,
      (file) => {
        if (!file) return true; // Empty field is allowed
        const maxSize = 1 * 1024 * 1024;
        return file.size <= maxSize;
      }
    )
    .nullable(),
});
