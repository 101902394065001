import axios from "axios";
import { useQuery } from "react-query";
import { API_URL } from "../config/env";

export const useGetCurrentWallet = () => {
  return useQuery(
    ["current-wallet"],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/wallet/current/details`,
        params: {},
      }),
    { select: (response) => response.data.results }
  );
};

export const useGetAllWallets = () => {
  return useQuery(
    ["all-wallet"],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/wallet/all-active/details`,
        params: {},
      }),
    { select: (response) => response.data.results }
  );
};