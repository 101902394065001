/* eslint-disable react/prop-types */
import { chunk } from "lodash";
import { useEffect, useState } from "react";
import { getFeatures } from "../../pricingHelper";
import "./PricingCardFour.css";
import { PRICING } from "../../../config/config";
const PricingCardFour = ({ pricing }) => {
  const [features, setFeatures] = useState([[], []]);

  useEffect(() => {
    const feat = getFeatures(pricing);
    setFeatures(chunk(feat, Math.ceil(feat?.length / 2)));
  }, [pricing]);

  return (
    <>
      <div className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-purple btn-h-outline-purple btn-a-outline-purple w-100 my-2 py-3 shadow-sm">
        {/* Premium Plan */}
        <div className="row align-items-center">
          <div className="col-12 col-md-4">
            <h4 className="pt-3 text-170 text-600 text-purple-d1 letter-spacing">
              {pricing?.Title}
            </h4>
            <div className="text-secondary-d1 text-120">
              <span className="ml-n15 align-text-bottom">
                {pricing?.Currency?.Label}
                {"  "}
              </span>
              <span className="text-180">{pricing?.Price}</span>
              {PRICING.showPriceDuration && (
                <>
                  /{" "}
                  {pricing?.DurationInDays
                    ? pricing?.DurationInDays
                    : "Unlimited"}{" "}
                  Days
                </>
              )}
            </div>
          </div>
          <ul className="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-4 my-md-0">
            {features[0]?.map((feat, i) => (
              <li key={i}>
                <GetIcon feat={feat} />
                {"   "}
                <span className="m-2">{feat.label}</span>
              </li>
            ))}
          </ul>
          <div className="col-12 col-md-4 text-center">
            <ul className="list-unstyled mb-0 col-12 text-dark-l1 text-90 text-left my-4 my-md-0">
              {features[1]?.map((feat, i) => (
                <li key={i}>
                  <GetIcon feat={feat} />
                  {"   "}
                  <span className="m-2">{feat.label}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingCardFour;
const GetIcon = ({ feat }) =>
  feat?.icon === "cross" ? (
    <i className="fa fa-times text-danger text-110 mr-25 mt-1" />
  ) : (
    <i className="fa fa-check text-success text-110 mr-2 mt-1" />
  );
