/* eslint-disable react/prop-types */

import { Link } from "react-router-dom";
import { timeSince } from "../../helpers/CommonHelper";
import { ROUTES } from "../../helpers/RouteHelper";
import { APP_CODE } from "../../config/env";

const JobCard = ({ job }) => {
  const timeAgo = timeSince(new Date(job?.CreatedAt));

  return (
    <>
      <div className="col-xl-4 col-md-6">
        <div
          className="job-bx style-1 wow fadeInUp"
          data-wow-delay="1.0s"
          style={{
            visibility: "visible",
            animationDelay: "1s",
            animationName: "fadeInUp",
          }}
        >
          <div className="d-flex m-b25 justify-content-between">
            <span className="media">
              <img src={`/${APP_CODE}/Logo.svg`} alt="image" />
            </span>
            {job?.JobTypes?.length > 0 && (
              <ul>
                <li>
                  <span className="job-time">
                    {job?.JobTypes?.map((jt) => jt?.Label)?.join("/")}
                  </span>
                </li>
              </ul>
            )}
          </div>
          <div className="job-contant">
            <h6 className="job-title ">
              <span>{job?.JobTitle}</span>
            </h6>
            <p className="text">
              <i className="fa fa-building fa-lg" aria-hidden="true"></i>
              <span className="m-1">{job?.CompanyName}</span>
            </p>
            <p className="text">
              <i className="fa fa-map-marker fa-2x" aria-hidden="true"></i>
              <span className="m-1">
                {job?.Location} - {job?.WorkLocation?.Label}
              </span>
            </p>
            {/* <h6 className="amount">{job?.WageCurrency?.Label}{job?.Wage}<span> / {job?.WageDuration?.Label}</span></h6> */}
          </div>
          <div className="jobs-amount">
            {/* <h6> */}
            {!timeAgo.includes("NaN") && (
              <span className="job-day" style={{ color: "var(--primary)" }}>
                {timeAgo} Ago
              </span>
            )}

            {/* </h6> */}
            <Link
              className="btn"
              style={{
                background: "var(--secondary)",
                borderColor: "var(--primary)",
                color: "var(--secondary-contrast)",
              }}
              aria-label="View Job"
              to={ROUTES.JOBS_VIEW.replace(":id", job?.id)}
            >
              <i className="fa fa-arrow-right" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const defaultProps = {};

const propTypes = {};

JobCard.propTypes = propTypes;
JobCard.defaultProps = defaultProps;

export default JobCard;
