export const ROUTES = {
  HOME: "/",
  JOBS: "/jobs",
  JOBS_POST: "/jobs/post",
  JOBS_VIEW: "/jobs/view/:id",
  JOB_FAIRS: "/job-fairs",
  PACKAGES: "/packages",
  RESOURCE_SUBMIT: "/resources/submit",
  RESOURCE_VIEW: "/resources/view",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  PRIVACY_POLICY: "/privacy-policy",
  BLOG_LIST: "/blog-list",
  BLOG_DETAILS: "/blog/:id",
  TERM_AND_CONDITIONS: "/terms-and-conditions",
  PAYMENT_PURCHASE: "/package/purchase",
  PAYMENT_SUCCESS: "/payment/success",
  PAYMENT_FAILURE: "/payment/failure",

  DASHBOARD: "/dashboard",
  DASHBOARD_JOB_LIST: "/dashboard/job-list",
  DASHBOARD_PROFILE: "/dashboard/user-profile",
  DASHBOARD_JOB_APPLIED: "/dashboard/job-applied",
};

export const listBlog = [
  {
    maintitle: "Pages",
    list: [
      { title: "Home", link: ROUTES.HOME },
      { title: "Jobs", link: ROUTES.JOBS },
      { title: "Blogs", link: ROUTES.BLOG_LIST },
    ],
  },
  {
    maintitle: "Useful Links",
    list: [
      { title: "Post a Job", link: ROUTES.PAYMENT_PURCHASE },
      { title: "Find a Job", link: ROUTES.JOBS },
      { title: "Login", link: ROUTES.LOGIN },
      // { title: "Register", link: ROUTES.REGISTER },
    ],
  },
  {
    maintitle: "Need help?",
    list: [
      { title: "About Us", link: ROUTES.ABOUT_US },
      { title: "Contact Us", link: ROUTES.CONTACT_US },
      { title: "Privacy Policy", link: ROUTES.PRIVACY_POLICY },
      { title: "Terms and Conditions", link: ROUTES.TERM_AND_CONDITIONS },
    ],
  },
];

export const menuItem = [
  {
    title: "Home",
    to: ROUTES.HOME,
    path: "1",
  },

  {
    title: "Jobs",
    to: ROUTES.JOBS,
    path: "2",
  },
  // {
  //   title: "Job Fairs",
  //   to: ROUTES.JOB_FAIRS,
  //   path: "3",
  // },
  {
    title: "Packages",
    backendUserMenu: true,
    to: ROUTES.PACKAGES,
    path: "4",
    isPackageNeeded: true,
  },
  // {
  //   title: "Resources",
  //   dropmenu: "sub-menu-down",
  //   content: [
  //     { subtitle: "Submit Resource", to: ROUTES.RESOURCE_SUBMIT, path: "5" },
  //     { subtitle: "View Resources", to: ROUTES.RESOURCE_VIEW, path: "6" },
  //   ],
  // },
  {
    title: "Blog",
    to: ROUTES.BLOG_LIST,
    path: "7",
  },
  // {
  //   title: "Blog",
  //   dropmenu: "sub-menu-down",
  //   content: [
  //     { subtitle: " Blog List", to: ROUTES.BLOG_LIST, path: "7" },
  //     { subtitle: "Blog Details", to: ROUTES.BLOG_DETAILS, path: "8" },
  //   ],
  // },
  // {
  //   title: "Need help? ",
  //   dropmenu: "sub-menu-down",
  //   content: [
  //     { subtitle: "About Us", to: ROUTES.ABOUT_US, path: "11" },
  //     { subtitle: "Contact Us", to: ROUTES.CONTACT_US, path: "12" },
  //   ],
  // },
  { title: "About Us", to: ROUTES.ABOUT_US, path: "11" },
  {
    title: "Dashboard",
    // backendUserMenu: true,
    isProtected: true,
    dropmenu: "sub-menu-down",
    content: [
      {
        subtitle: "Jobs List",
        to: ROUTES.DASHBOARD_JOB_LIST,
        path: "7",
        backendUserMenu: true,
      },
      {
        subtitle: "Profile",
        to: ROUTES.DASHBOARD_PROFILE,
        path: "8",
        // backendUserMenu: true,
      },
      {
        subtitle: "Applied Jobs",
        to: ROUTES.DASHBOARD_JOB_APPLIED,
        path: "10",
      },
    ],
  },
];
