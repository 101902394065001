/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useGetUserJobsInfinite } from "../../services/useJob";
import JobCard from "./JobCard";
import { JobCardSkeleton } from "../Skeletons";

const defaultProps = { maxResultsShow: 0 };

const propTypes = {};
const JobSectionLoadMore = ({ children,filterQuery, setTotalCount, maxResultsShow }) => {
  const { isLoading, isError,isFetchingNextPage, data, fetchNextPage, error } =
    useGetUserJobsInfinite(filterQuery);

  useEffect(() => {
    if (setTotalCount && data) {
      setTotalCount(data?.totalCount || "No");
    }
  }, [data, setTotalCount]);

  return (
    <>
      <div className="container">
        <div className="row">
          {isLoading ? (
            [1, 2, 3].map((x) => (
              <div className="col-xl-4 col-md-6" key={x}>
                <JobCardSkeleton />
              </div>
            ))
          ) : isError ? (
            <div>Error: {error.message}</div>
          ) : (
            data?.list?.map((job) => <JobCard key={job?.id} job={job} />)
          )}
          {data?.totalCount > data?.list?.length &&
            (maxResultsShow > data?.list?.length || maxResultsShow === 0) && (
              <button
                className="btn"
                style={{ background: 'var(--secondary)', borderColor: 'var(--primary)', color:'var(--secondary-contrast)' }}
                onClick={fetchNextPage}
                disabled={isLoading || isFetchingNextPage}
              >
                {isLoading || isFetchingNextPage ? "Loading...." : "Load More"}
              </button>
            )}
        </div>
      </div>
    </>
  );
};

JobSectionLoadMore.propTypes = propTypes;
JobSectionLoadMore.defaultProps = defaultProps;

export default JobSectionLoadMore;
