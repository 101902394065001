export const getFeatures = (pricing) => {
  if (pricing) {
    const feat = [];
    if (pricing?.DurationInDays > 0) {
      feat.push({
        label: `Membership expires in ${pricing?.DurationInDays} Days`,
        icon: pricing?.IsUnlimitedPosting === 0 ? "cross" : "",
      });
    }
    if (pricing?.IsUnlimitedDuration) {
      feat.push({
        label: `Membership never expires`,
        icon: pricing?.IsUnlimitedPosting === 0 ? "cross" : "",
      });
    }
    if (pricing?.NumberOfPostings > 0 || pricing?.IsUnlimitedPosting) {
      feat.push({
        label: `${
          pricing?.IsUnlimitedPosting ? "Unlimited" : pricing?.NumberOfPostings
        } job${pricing?.NumberOfPostings > 1 || pricing?.IsUnlimitedPosting ? "s": ""} posting`,
        icon: pricing?.IsUnlimitedPosting === 0 ? "cross" : "",
      });
    }
    if (pricing?.NumberOfFeaturedJob > 0) {
      feat.push({
        label: `${pricing?.NumberOfFeaturedJob || "No"} featured job`,
        icon: pricing?.NumberOfFeaturedJob === 0 ? "cross" : "",
      });
    }
    if (pricing?.NumberOfRefreshJob > 0) {
      feat.push({
        label: `${pricing?.NumberOfRefreshJob || "No"} refresh job`,
        icon: pricing?.NumberOfRefreshJob === 0 ? "cross" : "",
      });
    }
    if (pricing?.PostValidForDays > 0) {
      feat.push({
        label: `Post valid for ${pricing?.PostValidForDays} Days`,
        icon: pricing?.PostValidForDays === 0 ? "cross" : "",
      });
    }
    if (pricing?.DownloadResumeCount > 0) {
      feat.push({
        label: `${pricing?.DownloadResumeCount} download resumes`,
        icon: pricing?.DownloadResumeCount === 0 ? "cross" : "",
      });
    }
    feat.push({
      label: `Edit your job anytime in ${pricing?.PostValidForDays} Days`,
      icon: "",
    });
    // feat.push({
    //   label: `Post job with Job Type field`,
    //   icon: "",
    // });
    // feat.push({
    //   label: `Post job with Salary Offer field`,
    //   icon: "",
    // });
    // feat.push({
    //   label: `View Unlimited Resumes`,
    //   icon: "",
    // });
    return feat;
  }
};
