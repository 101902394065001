/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import { useState } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetPayment } from "../../services/usePayment";
import { PaymentSkeleton } from "../../components/Skeletons";

/* eslint-disable react/prop-types */
const defaultProps = {};

const propTypes = {};
const mapStateToProps = ({ app }) => ({
  UserName: app?.UserName,
  Email: app?.Email,
  UserId: app?.UserId,
});

const PaymentFailure = connect(
  mapStateToProps,
  null
)(({ UserName, Email, UserId }) => {
  const [searchParams] = useSearchParams();
  const [sessionId] = useState(() => searchParams.get("session_id") || "");
  if (!sessionId || !UserId) {
    return null;
  }
  const { isLoading, isError, isSuccess, data, error } = useGetPayment(
    `SessionId=${sessionId}&User=${UserId}`,
    !sessionId
  );
  return (
    <>
      <div className="container ">
        <div className="container-custom">
          <div className="printer-top" />
          {isLoading ? (
            <PaymentSkeleton />
          ) : (
            <div className="paper-container">
              <div className="printer-bottom" />
              <div className="paper ">
                <div className="main-contents">
                  <div className="success-icon  bg-danger">
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                  <div className="success-title">
                    Uh no payment failed <span> ):</span>
                  </div>
                  <div className="success-description ">
                    please try again payment has been failed!
                    <div className="bg-light mt-2">
                      <div className="text-dark ">Package : </div>
                      <div className="text-dark ">
                        {data?.[0]?.Pricing?.Title}
                      </div>
                    </div>
                  </div>
                  <div className="order-details">
                    <div className="order-number-label">Transaction Number</div>
                    <div className="order-number">{data?.[0]?.id}</div>
                  </div>
                  <div className="order-footer">Thank you!</div>
                </div>
                <div className="jagged-edge" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

PaymentFailure.propTypes = propTypes;
PaymentFailure.defaultProps = defaultProps;

export default PaymentFailure;
