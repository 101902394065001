/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import JobPostPayment from "../../components/Payment/JobPostPayment";
import { connect } from "react-redux";
import PricingSection from "../../components/pricing/PricingSection";
import { ROUTES } from "../../helpers/RouteHelper";
import { toastWarning } from "../../helpers/CommonHelper";
import { useGetPricing } from "../../services/usePricing";

const defaultProps = {};

const propTypes = {};

const mapStateToProps = ({ app }) => ({
  Email: app?.Email,
  IsPostingAllowed: app?.IsPostingAllowed,
});

const PaymentPage = connect(
  mapStateToProps,
  null
)(({ Email, IsPostingAllowed }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [priceParam] = useState(
    () => searchParams.get("selectedPackage") || ""
  );
  const { isLoading, isError, data, error } = useGetPricing(true);
  const [selectedPricing, setSelectedPricing] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // to preselect the pricing
    if (data && priceParam) {
      setSelectedPricing(() => data?.find((x) => x.id === priceParam));
    }
  }, [data, priceParam]);

  useEffect(() => {
    if (IsPostingAllowed === true) {
      toastWarning("You already have an active package");
      navigate(ROUTES.DASHBOARD_PROFILE);
    }
  }, [IsPostingAllowed, navigate]);

  return (
    <div className="container mb-5">
      {(isLoading) && <div className="loading" />}
      <div>
        {selectedPricing && <JobPostPayment email={Email} selectedPricing={selectedPricing} />}
      </div>
    </div>
  );
});

PaymentPage.propTypes = propTypes;
PaymentPage.defaultProps = defaultProps;

export default PaymentPage;
