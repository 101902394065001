/* eslint-disable no-unused-vars */
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { toastError, toastSuccess } from "../../helpers/CommonHelper";
import {
  useLogin,
  usePasswordReset,
  usePasswordResetOtp,
} from "../../services/useAuth";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/reducers/appSlice/appSlice";
import { onSuccess } from "./AuthHelper";
import { ROUTES } from "../../helpers/RouteHelper";
import { Link } from "react-router-dom";

const defaultProps = {};

const propTypes = {
  redirectTo: PropTypes.string,
};

const ResetPasswordForm = ({ redirectTo }) => {
  const [showOtp, setShowOtp] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const [apiError, setApiError] = useState("");
  const { mutate: mutateOTP, isLoading: isLoadingOTP } = usePasswordResetOtp();
  const { mutate: mutateReset, isLoading: isLoadingReset } = usePasswordReset();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={
        !showOtp
          ? {
              username: "",
            }
          : {
              username: "",
              otp: "",
              password: "",
              confirmPassword: "",
            }
      }
      validationSchema={
        !showOtp
          ? Yup.object({
              username: Yup.string().required("Email/Username is required"),
            })
          : Yup.object({
              username: Yup.string().required("Email/Username is required"),
              otp: showOtp
                ? Yup.string().nullable()
                : Yup.string()
                    .max(50, "Must be 50 characters or less")
                    .required("Password is required"),
              password: showOtp
                ? Yup.string().nullable()
                : Yup.string().required("Password is required"),
              confirmPassword: showOtp
                ? Yup.string().nullable()
                : Yup.string()
                    .required("Confirm Password is required")
                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
            })
      }
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        !showOtp
          ? mutateOTP(values, {
              onSuccess: async (response) => {
                const data = await response.json();
                setSubmitting(false);
                if ([200, 201].includes(response?.status)) {
                  toastSuccess(data || "Successfully reset password!");
                  setShowOtp(true);
                  dispatch(setUser(data));
                  if (redirectTo) {
                    navigate(redirectTo);
                  }
                } else if (response?.status === 400) {
                  setApiError(data);
                } else {
                  toastError(data || "Retry Again, Some error occurred!");
                }
              },
              onError: (error) => {
                setSubmitting(false);
                toastError("Retry Again, Some error occurred!");
              },
            })
          : mutateReset(values, {
              onSuccess: async (response) => {
                const data = await response.json();
                setSubmitting(false);
                if ([200, 201].includes(response?.status)) {
                  toastSuccess(data || "Successfully reset password!");
                  navigate(ROUTES.LOGIN);
                } else if (response?.status === 400) {
                  setApiError(data);
                } else {
                  toastError(data || "Retry Again, Some error occurred!");
                }
              },
              onError: (error) => {
                setSubmitting(false);
                toastError("Retry Again, Some error occurred!");
              },
            });
      }}
    >
      {({ errors, touched }) => (
        <Form className="mt-4 text-start">
          {/* <!-- Email --> */}
          <div className="mb-3">
            <label className="form-label">
              Enter the Username associated with an account.
            </label>
            {/* <input type="email" className="form-control" /> */}
            <Field
              name="username"
              type="text"
              placeholder="Username/ Email"
              className="form-control"
            />
            {errors.username && touched.username ? (
              <div className="text-danger">{errors.username}</div>
            ) : null}
          </div>
          {showOtp && (
            <>
              {/* <!-- Password --> */}
              <div className="mb-3 position-relative">
                <label className="form-label">Enter a new password</label>
                <Field
                  className="form-control"
                  type="password"
                  id="psw-input"
                  name="password"
                  placeholder="Enter password"
                />
                {errors.password && touched.password && (
                  <div className="error-message">{errors.password}</div>
                )}
              </div>
              {/* <!-- Confirm Password --> */}
              <div className="mb-3 position-relative">
                <label className="form-label">Confirm new password</label>
                <Field
                  className="form-control"
                  type={hidePassword ? "password" : "text"}
                  id="psw-input"
                  name="confirmPassword"
                  placeholder="Confirm password"
                />
                <span
                  className="position-absolute top-50 end-0 translate-middle-y p-0 mt-3"
                  onClick={() => setHidePassword(!hidePassword)}
                >
                  <i
                    className={`fakepasswordicon fas fa-eye-slash cursor-pointer p-2 ${
                      hidePassword ? "" : "fa-eye"
                    }`}
                  />
                </span>
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="error-message">{errors.confirmPassword}</div>
                )}
              </div>

              {/* <!-- OTP --> */}
              <div className="mb-3">
                <label className="form-label">
                  Enter OTP sended to Registered mail
                </label>
                <Field
                  name="otp"
                  placeholder={"One Time Password"}
                  type={"text"}
                  className="form-control"
                  id="otp"
                />
                {errors.password && touched.password ? (
                  <span className="text-danger">{errors.password}</span>
                ) : null}
              </div>
            </>
          )}

          <div className="mb-3 d-sm-flex justify-content-between">
            <Link disabled={isLoadingOTP || isLoadingReset} to={ROUTES.LOGIN}>
              Already have an Account?
            </Link>
          </div>

          <div>
            {apiError && <span className="text-danger">{apiError}</span>}
            <button
              type="submit"
              className="btn w-100 mb-0 button-animated"
              style={{
                background: "var(--secondary)",
                color: "var(--secondary-contrast)",
              }}
              disabled={isLoadingOTP || isLoadingReset}
            >
              {isLoadingOTP || isLoadingReset
                ? "Working on it, kindly wait..."
                : showOtp
                ? "Reset Password"
                : "Get OTP"}
            </button>
          </div>
          <div className="mb-3 d-sm-flex justify-content-between">
            <div>
              <label className="form-label">
                Didn't found the mail? Kindly check in your Spam list.
              </label>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = propTypes;
ResetPasswordForm.defaultProps = defaultProps;

export default ResetPasswordForm;
