/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import { useTranslation } from "react-i18next";
import { TENANT_CONFIG } from "../config/tenant";

/* eslint-disable react/prop-types */
const defaultProps = {};

const propTypes = {};
const WhyChooseUs = () => {
  const { t } = useTranslation();
  return (
    <section
      className="content-inner overflow-hidden position-relative "
      style={{ background: 'var(--primary)' }}
    >
      <div className="container">
        <div className="section-head text-center">
          <h6
            className="text wow fadeInUp"
            data-wow-delay="0.6s"
            style={{
              visibility: "visible",
              animationDelay: "0.6s",
              animationName: "fadeInUp",
            }}
          ></h6>
          <h2
            className="title wow fadeInUp text-light  mb-5"
            data-wow-delay="0.8s"
            style={{
              visibility: "visible",
              animationDelay: "0.8s",
              animationName: "fadeInUp",
            }}
          >
            {t("#home.why-choose-us.title", {
              app_title: TENANT_CONFIG.AppTitle,
            })}
          </h2>
        </div>
        <div className="row d-flex justify-content-center  ">
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="card icon-bx-wraper style-2 text-center wow fadeInUp"
              data-wow-delay="1.0s"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "fadeInUp",
              }}
            >
              <div className="card-body custom-card ">
                <div className="icon-content ">
                  <div className="icon-sm">
                    <i className="fa fa-rocket"></i>
                  </div>{" "}
                  <hr />
                  <h5 className="dlab-tilte ">
                    {t("#home.why-choose-us.cards-1-title")}
                  </h5>
                  <span>{t("#home.why-choose-us.cards-1-text")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="card icon-bx-wraper style-2 text-center wow fadeInUp"
              data-wow-delay="1.2s"
              style={{
                visibility: "visible",
                animationDelay: "1.2s",
                animationName: "fadeInUp",
              }}
            >
              <div className="card-body custom-card">
                <div className="icon-content">
                  <div className="icon-sm">
                    <i className="fa fa-user"></i>
                  </div>{" "}
                  <hr />
                  <h5 className="dlab-tilte">
                    {t("#home.why-choose-us.cards-2-title")}
                  </h5>
                  <span>{t("#home.why-choose-us.cards-2-text")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="card icon-bx-wraper style-2 text-center wow fadeInUp"
              data-wow-delay="1.4s"
              style={{
                visibility: "visible",
                animationDelay: "1.4s",
                animationName: "fadeInUp",
              }}
            >
              <div className="card-body custom-card">
                <div className="icon-content">
                  <div className="icon-sm">
                    <i className="fa fa-briefcase"></i>
                  </div>
                  <hr />
                  <h5 className="dlab-tilte">
                    {" "}
                    {t("#home.why-choose-us.cards-3-title")}
                  </h5>
                  <span>{t("#home.why-choose-us.cards-3-text")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="card icon-bx-wraper style-2 text-center wow fadeInUp"
              data-wow-delay="1.6s"
              style={{
                visibility: "visible",
                animationDelay: "1.6s",
                animationName: "fadeInUp",
              }}
            >
              <div className="card-body custom-card">
                <div className="icon-content">
                  <div className="icon-sm">
                    <i className="fa fa-thumbs-up"></i>
                  </div>
                  <hr />
                  <h5 className="dlab-tilte">
                    {t("#home.why-choose-us.cards-4-title")}
                  </h5>
                  <span>
                    {t("#home.why-choose-us.cards-4-text", {
                      app_title: TENANT_CONFIG.AppTitle,
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4 ">
            <div
              className="card icon-bx-wraper style-2 text-center wow fadeInUp"
              data-wow-delay="1.8s"
              style={{
                visibility: "visible",
                animationDelay: "1.8s",
                animationName: "fadeInUp",
              }}
            >
              <div className="card-body custom-card">
                <div className="icon-content">
                  <div className="icon-sm">
                    <i className="fa fa-comments-o"></i>
                  </div>
                  <hr />
                  <h5 className="dlab-tilte">
                    {t("#home.why-choose-us.cards-5-title")}
                  </h5>
                  <span>{t("#home.why-choose-us.cards-5-text")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WhyChooseUs.propTypes = propTypes;
WhyChooseUs.defaultProps = defaultProps;

export default WhyChooseUs;
