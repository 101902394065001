import axios from "axios";
import { useQuery } from "react-query";
import { API_URL } from "../config/env";

export const useGetPricing = (getAll = false) => {
  return useQuery(
    [`pricing-list-${getAll}`],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/pricing/user/details`,
        params: getAll ? {} : { IsActive: true },
      }),
    { select: (response) => response.data.results }
  );
};
