/* eslint-disable react/no-unescaped-entities */

import { useTranslation } from "react-i18next";
import "../components/HomeBannerTwo.css";
import { TENANT_CONFIG } from "../../../config/tenant";

const HomeBannerTwo = () => {
  const { t } = useTranslation();
  return (
    <section className="pb-0 pb-xl-5">
      <div className="container">
        <div className="row g-4 justify-content-between align-items-center">
          {/* Left side START */}
          <div className="col-lg-5 position-relative">
            {/* Svg Decoration */}
            {/* <figure className="position-absolute top-0 start-0 translate-middle z-index-1 ms-4">
              <svg className="fill-warning" width="77px" height="77px">
                <path d="M76.997,41.258 L45.173,41.258 L67.676,63.760 L63.763,67.673 L41.261,45.171 L41.261,76.994 L35.728,76.994 L35.728,45.171 L13.226,67.673 L9.313,63.760 L31.816,41.258 L-0.007,41.258 L-0.007,35.725 L31.816,35.725 L9.313,13.223 L13.226,9.311 L35.728,31.813 L35.728,-0.010 L41.261,-0.010 L41.261,31.813 L63.763,9.311 L67.676,13.223 L45.174,35.725 L76.997,35.725 L76.997,41.258 Z" />
              </svg>
            </figure> */}
            {/* Svg decoration */}
            <figure className="position-absolute bottom-0 end-0 d-none d-md-block mb-n5 me-n4">
              <svg
                height={400}
                className="fill-primary opacity-2"
                viewBox="0 0 340 340"
              >
                <circle cx="194.2" cy="2.2" r="2.2" />
                <circle cx="2.2" cy="2.2" r="2.2" />
                <circle cx="218.2" cy="2.2" r="2.2" />
                <circle cx="26.2" cy="2.2" r="2.2" />
                <circle cx="242.2" cy="2.2" r="2.2" />
                <circle cx="50.2" cy="2.2" r="2.2" />
                <circle cx="266.2" cy="2.2" r="2.2" />
                <circle cx="74.2" cy="2.2" r="2.2" />
                <circle cx="290.2" cy="2.2" r="2.2" />
                <circle cx="98.2" cy="2.2" r="2.2" />
                <circle cx="314.2" cy="2.2" r="2.2" />
                <circle cx="122.2" cy="2.2" r="2.2" />
                <circle cx="338.2" cy="2.2" r="2.2" />
                <circle cx="146.2" cy="2.2" r="2.2" />
                <circle cx="170.2" cy="2.2" r="2.2" />
                <circle cx="194.2" cy="26.2" r="2.2" />
                <circle cx="2.2" cy="26.2" r="2.2" />
                <circle cx="218.2" cy="26.2" r="2.2" />
                <circle cx="26.2" cy="26.2" r="2.2" />
                <circle cx="242.2" cy="26.2" r="2.2" />
                <circle cx="50.2" cy="26.2" r="2.2" />
                <circle cx="266.2" cy="26.2" r="2.2" />
                <circle cx="74.2" cy="26.2" r="2.2" />
                <circle cx="290.2" cy="26.2" r="2.2" />
                <circle cx="98.2" cy="26.2" r="2.2" />
                <circle cx="314.2" cy="26.2" r="2.2" />
                <circle cx="122.2" cy="26.2" r="2.2" />
                <circle cx="338.2" cy="26.2" r="2.2" />
                <circle cx="146.2" cy="26.2" r="2.2" />
                <circle cx="170.2" cy="26.2" r="2.2" />
                <circle cx="194.2" cy="50.2" r="2.2" />
                <circle cx="2.2" cy="50.2" r="2.2" />
                <circle cx="218.2" cy="50.2" r="2.2" />
                <circle cx="26.2" cy="50.2" r="2.2" />
                <circle cx="242.2" cy="50.2" r="2.2" />
                <circle cx="50.2" cy="50.2" r="2.2" />
                <circle cx="266.2" cy="50.2" r="2.2" />
                <circle cx="74.2" cy="50.2" r="2.2" />
                <circle cx="290.2" cy="50.2" r="2.2" />
                <circle cx="98.2" cy="50.2" r="2.2" />
                <circle cx="314.2" cy="50.2" r="2.2" />
                <circle cx="122.2" cy="50.2" r="2.2" />
                <circle cx="338.2" cy="50.2" r="2.2" />
                <circle cx="146.2" cy="50.2" r="2.2" />
                <circle cx="170.2" cy="50.2" r="2.2" />
                <circle cx="194.2" cy="74.2" r="2.2" />
                <circle cx="2.2" cy="74.2" r="2.2" />
                <circle cx="218.2" cy="74.2" r="2.2" />
                <circle cx="26.2" cy="74.2" r="2.2" />
                <circle cx="242.2" cy="74.2" r="2.2" />
                <circle cx="50.2" cy="74.2" r="2.2" />
                <circle cx="266.2" cy="74.2" r="2.2" />
                <circle cx="74.2" cy="74.2" r="2.2" />
                <circle cx="290.2" cy="74.2" r="2.2" />
                <circle cx="98.2" cy="74.2" r="2.2" />
                <circle cx="314.2" cy="74.2" r="2.2" />
                <circle cx="122.2" cy="74.2" r="2.2" />
                <circle cx="338.2" cy="74.2" r="2.2" />
                <circle cx="146.2" cy="74.2" r="2.2" />
                <circle cx="170.2" cy="74.2" r="2.2" />
                <circle cx="194.2" cy="98.2" r="2.2" />
                <circle cx="2.2" cy="98.2" r="2.2" />
                <circle cx="218.2" cy="98.2" r="2.2" />
                <circle cx="26.2" cy="98.2" r="2.2" />
                <circle cx="242.2" cy="98.2" r="2.2" />
                <circle cx="50.2" cy="98.2" r="2.2" />
                <circle cx="266.2" cy="98.2" r="2.2" />
                <circle cx="74.2" cy="98.2" r="2.2" />
                <circle cx="290.2" cy="98.2" r="2.2" />
                <circle cx="98.2" cy="98.2" r="2.2" />
                <circle cx="314.2" cy="98.2" r="2.2" />
                <circle cx="122.2" cy="98.2" r="2.2" />
                <circle cx="338.2" cy="98.2" r="2.2" />
                <circle cx="146.2" cy="98.2" r="2.2" />
                <circle cx="170.2" cy="98.2" r="2.2" />
                <circle cx="194.2" cy="122.2" r="2.2" />
                <circle cx="2.2" cy="122.2" r="2.2" />
                <circle cx="218.2" cy="122.2" r="2.2" />
                <circle cx="26.2" cy="122.2" r="2.2" />
                <circle cx="242.2" cy="122.2" r="2.2" />
                <circle cx="50.2" cy="122.2" r="2.2" />
                <circle cx="266.2" cy="122.2" r="2.2" />
                <circle cx="74.2" cy="122.2" r="2.2" />
                <circle cx="290.2" cy="122.2" r="2.2" />
                <circle cx="98.2" cy="122.2" r="2.2" />
                <circle cx="314.2" cy="122.2" r="2.2" />
                <circle cx="122.2" cy="122.2" r="2.2" />
                <circle cx="338.2" cy="122.2" r="2.2" />
                <circle cx="146.2" cy="122.2" r="2.2" />
                <circle cx="170.2" cy="122.2" r="2.2" />
                <circle cx="194.2" cy="146.2" r="2.2" />
                <circle cx="2.2" cy="146.2" r="2.2" />
                <circle cx="218.2" cy="146.2" r="2.2" />
                <circle cx="26.2" cy="146.2" r="2.2" />
                <circle cx="242.2" cy="146.2" r="2.2" />
                <circle cx="50.2" cy="146.2" r="2.2" />
                <circle cx="266.2" cy="146.2" r="2.2" />
                <circle cx="74.2" cy="146.2" r="2.2" />
                <circle cx="290.2" cy="146.2" r="2.2" />
                <circle cx="98.2" cy="146.2" r="2.2" />
                <circle cx="314.2" cy="146.2" r="2.2" />
                <circle cx="122.2" cy="146.2" r="2.2" />
                <circle cx="338.2" cy="146.2" r="2.2" />
                <circle cx="146.2" cy="146.2" r="2.2" />
                <circle cx="170.2" cy="146.2" r="2.2" />
                <circle cx="194.2" cy="170.2" r="2.2" />
                <circle cx="2.2" cy="170.2" r="2.2" />
                <circle cx="218.2" cy="170.2" r="2.2" />
                <circle cx="26.2" cy="170.2" r="2.2" />
                <circle cx="242.2" cy="170.2" r="2.2" />
                <circle cx="50.2" cy="170.2" r="2.2" />
                <circle cx="266.2" cy="170.2" r="2.2" />
                <circle cx="74.2" cy="170.2" r="2.2" />
                <circle cx="290.2" cy="170.2" r="2.2" />
                <circle cx="98.2" cy="170.2" r="2.2" />
                <circle cx="314.2" cy="170.2" r="2.2" />
                <circle cx="122.2" cy="170.2" r="2.2" />
                <circle cx="338.2" cy="170.2" r="2.2" />
                <circle cx="146.2" cy="170.2" r="2.2" />
                <circle cx="170.2" cy="170.2" r="2.2" />
                <circle cx="194.2" cy="194.2" r="2.2" />
                <circle cx="2.2" cy="194.2" r="2.2" />
                <circle cx="218.2" cy="194.2" r="2.2" />
                <circle cx="26.2" cy="194.2" r="2.2" />
                <circle cx="242.2" cy="194.2" r="2.2" />
                <circle cx="50.2" cy="194.2" r="2.2" />
                <circle cx="266.2" cy="194.2" r="2.2" />
                <circle cx="74.2" cy="194.2" r="2.2" />
                <circle cx="290.2" cy="194.2" r="2.2" />
                <circle cx="98.2" cy="194.2" r="2.2" />
                <circle cx="314.2" cy="194.2" r="2.2" />
                <circle cx="122.2" cy="194.2" r="2.2" />
                <circle cx="338.2" cy="194.2" r="2.2" />
                <circle cx="146.2" cy="194.2" r="2.2" />
                <circle cx="170.2" cy="194.2" r="2.2" />
                <circle cx="194.2" cy="218.2" r="2.2" />
                <circle cx="2.2" cy="218.2" r="2.2" />
                <circle cx="218.2" cy="218.2" r="2.2" />
                <circle cx="26.2" cy="218.2" r="2.2" />
                <circle cx="242.2" cy="218.2" r="2.2" />
                <circle cx="50.2" cy="218.2" r="2.2" />
                <circle cx="266.2" cy="218.2" r="2.2" />
                <circle cx="74.2" cy="218.2" r="2.2" />
                <circle cx="290.2" cy="218.2" r="2.2" />
                <circle cx="98.2" cy="218.2" r="2.2" />
                <circle cx="314.2" cy="218.2" r="2.2" />
                <circle cx="122.2" cy="218.2" r="2.2" />
                <circle cx="338.2" cy="218.2" r="2.2" />
                <circle cx="146.2" cy="218.2" r="2.2" />
                <circle cx="170.2" cy="218.2" r="2.2" />
                <circle cx="194.2" cy="242.2" r="2.2" />
                <circle cx="2.2" cy="242.2" r="2.2" />
                <circle cx="218.2" cy="242.2" r="2.2" />
                <circle cx="26.2" cy="242.2" r="2.2" />
                <circle cx="242.2" cy="242.2" r="2.2" />
                <circle cx="50.2" cy="242.2" r="2.2" />
                <circle cx="266.2" cy="242.2" r="2.2" />
                <circle cx="74.2" cy="242.2" r="2.2" />
                <circle cx="290.2" cy="242.2" r="2.2" />
                <circle cx="98.2" cy="242.2" r="2.2" />
                <circle cx="314.2" cy="242.2" r="2.2" />
                <circle cx="122.2" cy="242.2" r="2.2" />
                <circle cx="338.2" cy="242.2" r="2.2" />
                <circle cx="146.2" cy="242.2" r="2.2" />
                <circle cx="170.2" cy="242.2" r="2.2" />
                <circle cx="194.2" cy="266.2" r="2.2" />
                <circle cx="2.2" cy="266.2" r="2.2" />
                <circle cx="218.2" cy="266.2" r="2.2" />
                <circle cx="26.2" cy="266.2" r="2.2" />
                <circle cx="242.2" cy="266.2" r="2.2" />
                <circle cx="50.2" cy="266.2" r="2.2" />
                <circle cx="266.2" cy="266.2" r="2.2" />
                <circle cx="74.2" cy="266.2" r="2.2" />
                <circle cx="290.2" cy="266.2" r="2.2" />
                <circle cx="98.2" cy="266.2" r="2.2" />
                <circle cx="314.2" cy="266.2" r="2.2" />
                <circle cx="122.2" cy="266.2" r="2.2" />
                <circle cx="338.2" cy="266.2" r="2.2" />
                <circle cx="146.2" cy="266.2" r="2.2" />
                <circle cx="170.2" cy="266.2" r="2.2" />
                <circle cx="194.2" cy="290.2" r="2.2" />
                <circle cx="2.2" cy="290.2" r="2.2" />
                <circle cx="218.2" cy="290.2" r="2.2" />
                <circle cx="26.2" cy="290.2" r="2.2" />
                <circle cx="242.2" cy="290.2" r="2.2" />
                <circle cx="50.2" cy="290.2" r="2.2" />
                <circle cx="266.2" cy="290.2" r="2.2" />
                <circle cx="74.2" cy="290.2" r="2.2" />
                <circle cx="290.2" cy="290.2" r="2.2" />
                <circle cx="98.2" cy="290.2" r="2.2" />
                <circle cx="314.2" cy="290.2" r="2.2" />
                <circle cx="122.2" cy="290.2" r="2.2" />
                <circle cx="338.2" cy="290.2" r="2.2" />
                <circle cx="146.2" cy="290.2" r="2.2" />
                <circle cx="170.2" cy="290.2" r="2.2" />
                <circle cx="194.2" cy="314.2" r="2.2" />
                <circle cx="2.2" cy="314.2" r="2.2" />
                <circle cx="218.2" cy="314.2" r="2.2" />
                <circle cx="26.2" cy="314.2" r="2.2" />
                <circle cx="242.2" cy="314.2" r="2.2" />
                <circle cx="50.2" cy="314.2" r="2.2" />
                <circle cx="266.2" cy="314.2" r="2.2" />
                <circle cx="74.2" cy="314.2" r="2.2" />
                <circle cx="290.2" cy="314.2" r="2.2" />
                <circle cx="98.2" cy="314.2" r="2.2" />
                <circle cx="314.2" cy="314.2" r="2.2" />
                <circle cx="122.2" cy="314.2" r="2.2" />
                <circle cx="338.2" cy="314.2" r="2.2" />
                <circle cx="146.2" cy="314.2" r="2.2" />
                <circle cx="170.2" cy="314.2" r="2.2" />
                <circle cx="194.2" cy="338.2" r="2.2" />
                <circle cx="2.2" cy="338.2" r="2.2" />
                <circle cx="218.2" cy="338.2" r="2.2" />
                <circle cx="26.2" cy="338.2" r="2.2" />
                <circle cx="242.2" cy="338.2" r="2.2" />
                <circle cx="50.2" cy="338.2" r="2.2" />
                <circle cx="266.2" cy="338.2" r="2.2" />
                <circle cx="74.2" cy="338.2" r="2.2" />
                <circle cx="290.2" cy="338.2" r="2.2" />
                <circle cx="98.2" cy="338.2" r="2.2" />
                <circle cx="314.2" cy="338.2" r="2.2" />
                <circle cx="122.2" cy="338.2" r="2.2" />
                <circle cx="338.2" cy="338.2" r="2.2" />
                <circle cx="146.2" cy="338.2" r="2.2" />
                <circle cx="170.2" cy="338.2" r="2.2" />
              </svg>
            </figure>
            {/* Image */}
            <img
              src="/Canada_bg_01.png"
              className="rounded-3 position-relative"
              alt=""
            />
          </div>
          {/* Left side END */}
          {/* Right side START */}
          <div className="col-lg-6">
            <h2 className="mb-3 mb-lg-5">{TENANT_CONFIG.AppTitle}!</h2>
            <p className="mb-3 mb-lg-5">
              {t("#home.homebannertwo.sub-title", {
                app_title: TENANT_CONFIG.AppTitle,
              })}
            </p>
            {/* Features START */}
            <div className="row g-4">
              {/* Item */}
              <div className="col-sm-12">
                <div className="icon-lg bg-success bg-opacity-10 text-success rounded-circle">
                  <i className="fa-solid fa-briefcase" />
                </div>
                <h5 className="mt-2">
                  {t("#home.homebannertwo.content.title-01", {
                    app_title: TENANT_CONFIG.AppTitle,
                  })}
                </h5>
                <p className="mb-0">
                  {t("#home.homebannertwo.content.description-01", {
                    app_title: TENANT_CONFIG.AppTitle,
                  })}
                </p>
              </div>
              {/* Item */}
              <div className="col-sm-12">
                <div className="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle">
                  <i className="fa-solid fa-trophy" />
                </div>
                <h5 className="mt-2">
                  {t("#home.homebannertwo.content.title-02", {
                    app_title: TENANT_CONFIG.AppTitle,
                  })}
                </h5>
                <p className="mb-0">
                  {t("#home.homebannertwo.content.description-02", {
                    app_title: TENANT_CONFIG.AppTitle,
                  })}
                </p>
              </div>
            </div>
            {/* Features END */}
          </div>
          {/* Right side END */}
        </div>
      </div>
    </section>
  );
};

export default HomeBannerTwo;
