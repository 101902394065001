/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { toastError } from "../../helpers/CommonHelper";
import { useCreateCheckoutSession } from "../../services/useStripe";
import { ROUTES } from "../../helpers/RouteHelper";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const JobPostPayment = ({ email, selectedPricing }) => {
  const [paymenturl, setPaymentUrl] = useState("");
  const { mutate, isLoading, isSuccess } = useCreateCheckoutSession();
  const isPaymentUrl = useRef(false);
  const createCheckoutSession = () => {
    mutate(
      {
        success_url: `${window.location.origin}${ROUTES.PAYMENT_SUCCESS}?session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${window.location.origin}${ROUTES.PAYMENT_FAILURE}?session_id={CHECKOUT_SESSION_ID}`,
        pricing_id: selectedPricing.id,
      },
      {
        onSuccess: async (response) => {
          const data = await response.json();
          if ([200, 201].includes(response?.status)) {
            setPaymentUrl(data?.PaymentUrl);
          } else {
            toastError(data?.detail || "Retry Again, Some error occurred!");
          }
          // eslint-disable-next-line no-undef
          gtag?.("event", "begin_checkout", {
            value: selectedPricing?.Price,
            currency: selectedPricing?.Currency?.Code,
            user_email: email,
            items: [
              {
                item_id: selectedPricing?.id,
                item_name: selectedPricing?.Title,
                price: selectedPricing?.Price,
                quantity: 1
              },
            ],
          });
        },
        onError: (err) => {
          toastError();
        },
      }
    );
  };

  useEffect(() => {
    if (selectedPricing.id && !isPaymentUrl.current) {
      isPaymentUrl.current = true;
      createCheckoutSession();
    }
  }, []);

  return (
    <section className="price-wrap pb-5 pt-sm-0">
      <div className="container">
        <div className="row">
          {/* Title */}
          <div className="col-xl-10 mx-auto text-center">
            <h6 style={{ color: "var(--primary)" }}></h6>
            <h1 className="mb-2">Payment</h1>
            <Link to={"http://jobportalpaymentcan.ca/"} className="logo-dark" target="_blank">
              <img
                width={"150px"}
                src="/jobportalpaymentcan/Logo_Square.png"
                alt={" Logo"}
              />
            </Link>
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                <div className="bg-white shadow-sm rounded p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4">
                  <h3 className="text-5 fw-400 mb-3 mb-sm-4">
                    Personal Details
                  </h3>
                  <hr className="mx-n3 mx-sm-n5 mb-4" />

                  <div className="mb-3">
                    <label htmlFor="emailID" className="form-label">
                      Recipient
                    </label>
                    <span className="form-control">{email}</span>
                  </div>
                  <hr />
                  <p>
                    Selected Package
                    <span className="float-end">
                      {selectedPricing?.Description}
                    </span>
                  </p>
                  <hr />
                  <p className="text-4 fw-500">
                    Total To Pay
                    <span className="float-end">
                      {selectedPricing?.Currency?.Label}{" "}
                      {selectedPricing?.Price}
                    </span>
                  </p>
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn"
                      style={{
                        background: "var(--primary)",
                        color: "var(--primary-contrast)",
                      }}
                      disabled={!paymenturl || isLoading}
                      onClick={() => window.open(paymenturl, "_self")}
                    >
                      {isLoading ? "Kindly wait" : "Continue to Payment"}
                    </button>
                  </div>
                  <p className="text-muted text-center pt-5">
                    Kindly do not refresh page or go back{" "}
                  </p>

                  <p className="text-muted text-center pt-5">
                    All communication and payments will be made with our domain{" "}
                    <a
                      target="_blank"
                      href="http://jobportalpaymentcan.ca/"
                      rel="noreferrer"
                    >
                      jobportalpaymentcan.ca
                    </a>
                  </p>

                  {/* Send Money Form end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobPostPayment;
