import axios from "axios";
import { useQuery } from "react-query";
import { API_URL } from "../config/env";

export const useGetMasters = () => {
  return useQuery(
    ["master-list-data"],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/master/meta/details?IsActive=true`,
        params: {},
      }),
    { select: (response) => response.data }
  );
};
