import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getFeatures } from "../../pricingHelper";
import { ROUTES } from "../../../helpers/RouteHelper";
import { PRICING } from "../../../config/config";
import { useSelector } from "react-redux";

/* eslint-disable react/prop-types */
const PricingCardOne = ({ pricing, selected }) => {
  const discount = useSelector(
    (state) => state.master?.couponCodeList?.filter((x) => x?.IsActive)?.[0]
  );
  const navigate = useNavigate();
  const [features, setFeatures] = useState([]);
  const [discountPrice, setDiscountPrice] = useState(null);

  useEffect(() => {
    setFeatures(getFeatures(pricing));
  }, [pricing]);

  useEffect(() => {
    const disPrice = pricing?.Price - (pricing?.Price * +discount?.value) / 100;
    setDiscountPrice(Math.round(disPrice,2));
  }, [pricing, discount]);

  return (
    <>
      {/* Price item START */}
      <div className="col-md-6 col-lg-4 pb-3">
        <div
          className={selected ? "card border border-primary" : "card shadow"}
        >
          <h5 className="card-header text-danger text-center blink-soft">
            {discount?.label}
          </h5>
          {/* Card header */}
          <div className="card-header d-flex justify-content-between align-items-center border-bottom p-4">
            <div>
              <h6 className="mb-0">{pricing?.Title}</h6>
              <div className="hstack gap-2">
                <span
                  className="h3 plan-price mb-0"
                  data-monthly-price="$20"
                  data-annual-price="$570"
                  style={{
                    textDecoration:
                      discount && discountPrice ? "line-through" : "",
                  }}
                >
                  {pricing?.Currency?.Label}
                  {"  "}
                  {pricing?.Price}
                </span>
                {discount && discountPrice && (
                  <span className="h3 plan-price mb-0 text-danger">
                    {pricing?.Currency?.Label}
                    {discountPrice}
                  </span>
                )}
                {PRICING.showPriceDuration && (
                  <span className="h6 fw-light mb-0">
                    / per{" "}
                    {pricing?.DurationInDays
                      ? pricing?.DurationInDays
                      : "Unlimited"}{" "}
                    Days
                  </span>
                )}
              </div>
            </div>
            <span
              className="icon-lg bg-light rounded-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
            >
              <i className="bi bi-lightning-charge-fill h5 lh-1" />
            </span>
          </div>
          {/* Card body */}
          <div className="card-body p-4">
            {/* List */}
            <ul className="list-unstyled mb-0">
              {features?.map((feat, i) => (
                <li className="mb-2" key={i}>
                  <span className="m-2">
                    <GetIcon feat={feat} />
                  </span>
                  {feat.label}
                </li>
              ))}
            </ul>
          </div>
          {/* Card footer */}
          <div className="card-footer">
            <div className="d-grid gap-2">
              <button
                className={`btn`}
                style={{
                  background: pricing?.IsSubscription
                    ? "var(--primary)"
                    : "var(--secondary)",
                  borderColor: "var(--primary)",
                  color: "var(--secondary-contrast)",
                }}
                onClick={() => {
                  navigate(
                    `${ROUTES.PAYMENT_PURCHASE}?selectedPackage=${pricing.id}`
                  );
                }}
              >
                {pricing?.IsSubscription ? "Subscribe now!" : "Purchase now!"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingCardOne;

const GetIcon = ({ feat }) =>
  feat?.icon === "cross" ? (
    <i className="fa fa-times-circle text-danger text-110 mr-25 mt-1" />
  ) : (
    <i className="fa fa-check-circle text-success text-110 mr-2 mt-1" />
  );
