/* eslint-disable no-unused-vars */
import { useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useRegister } from "../../services/useAuth";
import { toastError, toastSuccess } from "../../helpers/CommonHelper";
import { setUser } from "../../store/reducers/appSlice/appSlice";
import { onSuccess } from "./AuthHelper";
import { LOGIN_TYPE } from "../../helpers/typeCd";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/RouteHelper";

const defaultProps = {};

const propTypes = {
  redirectTo: PropTypes.string,
  loginAs: PropTypes.string,
};
const SignUpForm = ({ redirectTo, loginAs }) => {
  const [hidePassword, setHidePassword] = useState(true);
  const [apiError, setApiError] = useState("");
  const { mutate, isLoading, isSuccess, isError } = useRegister();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        username: "",
        password: "",
        confirmPassword: "",
        is_frontend_user: loginAs !== LOGIN_TYPE.EMPLOYER,
        is_backend_user: loginAs === LOGIN_TYPE.EMPLOYER,
      }}
      validationSchema={Yup.object({
        first_name: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("First Name is Required"),
        last_name: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Last Name is Required"),
        username: Yup.string()
          .email("invalid Email id/ User id")
          .required("Email id/ User id is Required"),
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password should be min 8 characters"),
        is_frontend_user: Yup.boolean(),
        is_backend_user: Yup.boolean(),
        confirmPassword: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        const formData = JSON.parse(JSON.stringify(values));
        formData.email = formData.username;
        delete formData.confirmPassword;
        mutate(formData, {
          onSuccess: async (response) => {
            onSuccess(
              response,
              setSubmitting,
              dispatch,
              redirectTo,
              navigate,
              setFieldError,
              setApiError
            );
            // eslint-disable-next-line no-undef
            gtag?.("event", "sign_up", {
              method: "username_with_password",
            });
          },
          onError: (error) => {
            setSubmitting(false);
            toastError("Retry Again, Some error occurred!");
          },
        });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        resetForm,
        validateForm,
      }) => (
        <Form>
          {/* <!-- Form START --> */}
          <div className="mt-4 text-start">
            <label className="form-label">Sign up as</label>
            <div
              className="btn-group btn-group-toggle w-100 mb-0"
              data-toggle="buttons"
            >
              <label
                className={`btn ${
                  values.is_frontend_user
                    ? "btn-primary active"
                    : "btn-secondary"
                }`}
              >
                {/* <input
                type="radio"
                checked={values.is_frontend_user}
                onChange={() => {
                  setFieldValue("is_frontend_user", true)
                  setFieldValue("is_backend_user", false)
                }}
              /> */}
                Candidate
              </label>
              <label
                className={`btn ${
                  values.is_backend_user
                    ? "btn-primary active"
                    : "btn-secondary"
                }`}
              >
                {/* <input
                  type="radio"
                  checked={values.is_backend_user}
                  onChange={() => {
                    setFieldValue("is_backend_user", true)
                    setFieldValue("is_frontend_user", false)
                  }}
                /> */}
                Employer
              </label>
            </div>
            {/* <!-- Name --> */}
            <div className="mb-3 pt-4">
              <label className="form-label">Enter your Name</label>
              <div className="row">
                <div className="col-sm-6">
                  <Field
                    type="text"
                    className="form-control"
                    name="first_name"
                    placeholder="First name"
                  />

                  {errors.first_name && touched.first_name && (
                    <div className="error-message ">{errors.first_name}</div>
                  )}
                </div>
                <div className="col-sm-6">
                  <Field
                    type="text"
                    className="form-control"
                    name="last_name"
                    placeholder="Last name"
                  />
                  {errors.last_name && touched.last_name && (
                    <div className="error-message ">{errors.last_name}</div>
                  )}
                </div>
              </div>
            </div>
            {/* <!-- Email --> */}
            <div className="mb-3">
              <label className="form-label">Enter email id</label>
              <Field
                type="email"
                className="form-control"
                name="username"
                placeholder="Enter Email id"
              />
              {errors.username && touched.username && (
                <div className="error-message">{errors.username}</div>
              )}
            </div>
            {/* <!-- Password --> */}
            <div className="mb-3 position-relative">
              <label className="form-label">Enter password</label>
              <Field
                className="form-control"
                type="password"
                id="psw-input"
                name="password"
                placeholder="Enter password"
              />
              {errors.password && touched.password && (
                <div className="error-message">{errors.password}</div>
              )}
            </div>
            {/* <!-- Confirm Password --> */}
            <div className="mb-3 position-relative">
              <label className="form-label">Confirm password</label>
              <Field
                className="form-control"
                type={hidePassword ? "password" : "text"}
                id="psw-input"
                name="confirmPassword"
                placeholder="Confirm password"
              />
              <span
                className="position-absolute top-50 end-0 translate-middle-y p-0 mt-3"
                onClick={() => setHidePassword(!hidePassword)}
              >
                <i
                  className={`fakepasswordicon fas fa-eye-slash cursor-pointer p-2 ${
                    hidePassword ? "" : "fa-eye"
                  }`}
                />
              </span>
              {errors.confirmPassword && touched.confirmPassword && (
                <div className="error-message">{errors.confirmPassword}</div>
              )}
            </div>
            <div>
              {apiError && <span className="text-danger">{apiError}</span>}
              <button
                type="submit"
                className="btn w-100 mb-0 button-animated"
                style={{
                  background: "var(--secondary)",
                  color: "var(--secondary-contrast)",
                }}
                disabled={isLoading}
              >
                {isLoading ? "Signing you Up, Kindly wait" : "Sign Up"}
              </button>
            </div>
            <div className="mb-3 d-sm-flex justify-content-between">
              <div>
                <label className="form-label">
                  By signing up, you are agreeing to our{" "}
                  <Link to={ROUTES.TERM_AND_CONDITIONS}>terms of service</Link>{" "}
                  and
                  <Link to={ROUTES.PRIVACY_POLICY}> privacy and policy</Link> to
                  use the service.
                </label>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SignUpForm.propTypes = propTypes;
SignUpForm.defaultProps = defaultProps;

export default SignUpForm;
