import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getFeatures } from "../../pricingHelper";
import { ROUTES } from "../../../helpers/RouteHelper";
import { PRICING } from "../../../config/config";
import { useSelector } from "react-redux";

/* eslint-disable react/prop-types */

const PricingCardTwo = ({ pricing }) => {
  const discount = useSelector(
    (state) => state.master?.couponCodeList?.filter((x) => x?.IsActive)?.[0]
  );
  const navigate = useNavigate();
  const [features, setFeatures] = useState([]);
  const [discountPrice, setDiscountPrice] = useState(null);

  useEffect(() => {
    setFeatures(getFeatures(pricing));
  }, [pricing]);

  useEffect(() => {
    const disPrice = pricing?.Price - (pricing?.Price * +discount?.value) / 100;
    setDiscountPrice(Math.round(disPrice,2));
  }, [pricing, discount]);

  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 m-b30 pb-3">
        <div
          className="pricing-wrapper rounded wow fadeInUp"
          data-wow-delay="1.0s"
          style={{
            visibility: "visible",
            animationDelay: "1s",
            animationName: "fadeInUp",
          }}
        >
          <div className="pricing-inner">
            <div className="Package-title text-center blink-soft pb-4">
              <h5 className="title text-danger">{discount?.label}</h5>
            </div>
            <div className="Package-title">
              <h4 className="title">{pricing?.Title}</h4>
            </div>
            <div className="Package-price">
              <span style={{ textDecoration: discount && discountPrice ? "line-through" : "" }}>
                {pricing?.Currency?.Label}
                {pricing?.Price}
              </span>
              {discount && discountPrice && (
                <span className="text-danger">
                  {pricing?.Currency?.Label}
                  {discountPrice}
                </span>
              )}
              {PRICING.showPriceDuration && (
                <p>
                  / per{" "}
                  {pricing?.DurationInDays
                    ? pricing?.DurationInDays
                    : "Unlimited"}{" "}
                  Days
                </p>
              )}
            </div>

            <div className="Package-list">
              <ul>
                {features?.map((feat, i) => (
                  <li key={i}>
                    <span className="m-2">
                      <GetIcon feat={feat} />
                    </span>
                    {feat.label}
                  </li>
                ))}
              </ul>
            </div>
            <button
              className={`btn btn-lg`}
              style={{
                background: pricing?.IsSubscription
                  ? "var(--primary)"
                  : "var(--secondary)",
                borderColor: "var(--primary)",
                color: "var(--secondary-contrast)",
              }}
              onClick={() => {
                navigate(
                  `${ROUTES.PAYMENT_PURCHASE}?selectedPackage=${pricing.id}`
                );
              }}
            >
              {pricing?.IsSubscription ? "Subscribe Now" : "Purchase Now"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingCardTwo;

const GetIcon = ({ feat }) =>
  feat?.icon === "cross" ? (
    <i className="fa fa-times text-danger text-110 mr-25 mt-1" />
  ) : (
    <i className="fa fa-check text-success text-110 mr-2 mt-1" />
  );
