import { createSlice } from "@reduxjs/toolkit";
import { GetMasterList } from "../../../helpers/CommonHelper";
import { MASTER_CODES } from "../../../helpers/typeCd";
import { initialState } from "./initialState";

const master = createSlice({
  name: "master",
  initialState,
  reducers: {
    setMasters(state, { payload }) {
      if(payload?.length > 0) {
        state.currencyList = GetMasterList(payload, MASTER_CODES.CURRENCY, false);
        state.educationList = GetMasterList(payload, MASTER_CODES.EDUCATION, false);
        state.languageList = GetMasterList(payload, MASTER_CODES.LANGUAGE, false);
        state.durationTypeList = GetMasterList(payload, MASTER_CODES.DURATION_TYPE, false);
        state.employerTypeList = GetMasterList(payload, MASTER_CODES.EMPLOYER_TYPE, false);
        state.jobCategoryList = GetMasterList(payload, MASTER_CODES.JOB_CATEGORY, false);
        state.jobStatusList = GetMasterList(payload, MASTER_CODES.JOB_STATUS, false);
        state.jobTypeList = GetMasterList(payload, MASTER_CODES.JOB_TYPE, false);
        state.territoryList = GetMasterList(payload, MASTER_CODES.TERRITORY, false);
        state.workLocationList = GetMasterList(payload, MASTER_CODES.WORK_LOCATION, false);
        state.couponCodeList = GetMasterList(payload, MASTER_CODES.COUPON_CODE, true);
        state.remoteAppConfig = GetMasterList(payload, MASTER_CODES.REMOTE_CONFIG, true);
      }
    },
  },
});

export const { setMasters } = master.actions;

export default master.reducer;
