import { useEffect } from "react";

/* eslint-disable react/prop-types */
const ComingSoon = ({ title, isNotFound }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="p-xl-0">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center text-center vh-100">
            <div className="col-xl-8">
              {/* Title */}
              <h6 style={{ color: 'var(--primary)' }}>
                {isNotFound ? "Page not found" : "Coming Soon"}
              </h6>
              <h1>
                {title}
                {!isNotFound && ` is on a roll!!`}
              </h1>
              <p className="mb-0">
                {isNotFound
                  ? ""
                  : `Hey, you! ${title} is coming. We are doing our best to launch
                more features and we will be back soon.`}
              </p>
              {/* Image */}
              <img src="/coming-soon.svg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComingSoon;
