import { useEffect, useState } from "react";
import SignUpForm from "../../components/Auth/SignUpForm";
import { ROUTES } from "../../helpers/RouteHelper";
import { Link, useSearchParams } from "react-router-dom";
import { SEO, SeoSection } from "../../helpers/SEOHelper";
import { APP_CODE } from "../../config/env";

const RegisterPage = () => {
  const [searchParams] = useSearchParams();
  const [redirectTo] = useState(
    () => searchParams.get("redirectTo") || ROUTES.HOME
  );
  const [loginAs] = useState(() => searchParams.get("loginAs"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <SeoSection PAGE={SEO.REGISTER} />
      <div className="form-container sign-in-container mb-5">
        <div className="container">
          <section className="vh-xxl-100 pt-5">
            <div className="container h-100 d-flex px-0 px-sm-4">
              <div className="row justify-content-center align-items-center m-auto">
                <div className="col-12">
                  <div className="bg-mode shadow rounded-3 overflow-hidden">
                    <div className="row g-0">
                      {/* <!-- Vector Image --> */}
                      <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                        <div className="p-3 p-lg-5">
                          <img className="img-fluid" src="/signin.svg" alt="" />
                        </div>
                        {/* <!-- Divider --> */}
                        <div className="vr opacity-1 d-none d-lg-block"></div>
                      </div>

                      {/* <!-- Information --> */}
                      <div className="col-lg-6 order-1">
                        <div className="p-4 p-sm-7">
                          {/* <!-- Logo --> */}
                          <Link to={ROUTES.HOME}>
                            <img
                              className="h-50px mb-4"
                              src={`/${APP_CODE}/Logo.svg`}
                              alt="logo"
                              style={{
                                width: "100px",
                              }}
                            />
                          </Link>
                          {/* <!-- Title --> */}
                          <h1 className="mb-2 h3">Create new account</h1>
                          <p className="mb-0">
                            {"Already a member? "}
                            <Link
                              to={`${ROUTES.LOGIN}?loginAs=${loginAs}&redirectTo=${redirectTo}`}
                            >
                              Login
                            </Link>
                          </p>
                          <SignUpForm
                            redirectTo={redirectTo}
                            loginAs={loginAs}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
