import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import axios from "axios";
import { isDateValid, reloadScreen } from "../../../helpers/CommonHelper";

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLocalization(state, { payload }) {
      state.localization = payload;
    },
    setUser(state, { payload }) {
      if (payload?.UserId && payload?.AccessToken) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload?.AccessToken}`;
        state.isAuthenticated = true;
        state.RefreshToken = payload?.RefreshToken;
        state.AccessToken = payload?.AccessToken;
        state.Email = payload?.Email;
        state.UserId = payload?.UserId;
        state.UserName = payload?.UserName;
        state.User = {
          IsBackendUser: payload?.User?.is_backend_user,
          IsFrontendUser: payload?.User?.is_frontend_user,
          LastLogin: payload?.User?.last_login,
          FirstName: payload?.User?.first_name,
          LastName: payload?.User?.last_name,
          Permissions: payload?.Permissions,
          PhoneNumber: payload?.phone_number,
          Groups: payload?.Groups,
          IsEmailVerified: payload?.User?.is_email_confirmed,
        };
      }
    },
    clearUser(state) {
      axios.defaults.headers.common["Authorization"] = ``;
      state.isAuthenticated = false;
      state.RefreshToken = initialState.RefreshToken;
      state.AccessToken = initialState.AccessToken;
      state.Email = initialState.Email;
      state.UserId = initialState.UserId;
      state.UserName = initialState.UserName;
      state.User = initialState.User;
      state.UserActiveWallet = initialState.UserActiveWallet;
      reloadScreen();
    },
    setUserActiveWallet(state, { payload }) {
      const actWallet = payload?.find((x) => !x.WalletExpired);
      state.UserActiveWallet = actWallet;
      let isValidPosting = !actWallet.WalletExpired;
      if (isValidPosting) {
        isValidPosting =
          actWallet?.Pricing?.IsUnlimitedPosting ||
          actWallet?.PostedJobsCount < actWallet?.Pricing?.NumberOfPostings;
      }
      if (isValidPosting) {
        isValidPosting = isDateValid(actWallet?.WalletActiveTill);
      }
      state.IsPostingAllowed = isValidPosting;
      state.refetchFlags = { ...state.refetchFlags, activeWallet: false };
    },
    setUserAllWallet(state, { payload }) {
      state.UserAllWallet = payload;
    },
    setTenants(state, { payload }) {
      state.Tenants = payload;
    },
    setRefetch(state, { payload }) {
      state.refetchFlags = payload;
    },
  },
});

export const {
  setLocalization,
  setUser,
  clearUser,
  setUserActiveWallet,
  setUserAllWallet,
  setTenants,
  setRefetch,
} = app.actions;

export default app.reducer;

export const saveRefetchFlags = (refetchObject) => (dispatch, getState) => {
  const refetchFlags = getState().app.refetchFlags;
  dispatch(setRefetch({ ...refetchFlags, ...refetchObject }));
};
