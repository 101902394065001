import { useMutation } from "react-query";
import { API_URL } from "../config/env";
import { postReq } from "./api";

export const useLogin = () => {
  return useMutation({
    mutationFn: (values) => postReq(`${API_URL}/token`, values, false),
  });
};

export const useRegister = () => {
  return useMutation({
    mutationFn: (values) =>
      postReq(`${API_URL}/account/user/frontend/create`, values, false),
  });
};

export const useTokenVerify = () => {
  return useMutation({
    mutationFn: (values) => postReq(`${API_URL}/token/verify`, values, false),
  });
};


export const usePasswordResetOtp = () => {
  return useMutation({
    mutationFn: (values) => postReq(`${API_URL}/api/user/reset-password-otp`, values, false),
  });
};

export const useVerifyResendOtp = () => {
  return useMutation({
    mutationFn: (values) => postReq(`${API_URL}/api/user/verify-email/resend`, values),
  });
};

export const usePasswordReset = () => {
  return useMutation({
    mutationFn: (values) => postReq(`${API_URL}/api/user/reset-password`, values, false),
  });
};

export const useVerifyEmail = () => {
  return useMutation({
    mutationFn: (values) => postReq(`${API_URL}/api/user/verify-email`, values, true),
  });
};