/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useSearchParams } from "react-router-dom";
import "../payment/PaymentSuccess.css";
import { useState } from "react";
import { useGetPayment } from "../../services/usePayment";
import { connect } from "react-redux";
import { PaymentSkeleton } from "../../components/Skeletons";
import { useEffect } from "react";
const defaultProps = {};

const propTypes = {};

const mapStateToProps = ({ app }) => ({
  UserName: app?.UserName,
  Email: app?.Email,
  UserId: app?.UserId,
});

const PaymentSuccess = connect(
  mapStateToProps,
  null
)(({ UserName, Email, UserId }) => {
  const [searchParams] = useSearchParams();
  const [sessionId] = useState(() => searchParams.get("session_id") || "");
  if (!sessionId || !UserId) {
    return null;
  }
  const { isLoading, isError, isSuccess, data, error } = useGetPayment(
    `SessionId=${sessionId}&User=${UserId}`
  );
  useEffect(() => {
    try {
      if (data) {
        gtag?.("event", "purchase", {
          transaction_id: data?.[0]?.id,
          value: data?.[0]?.Pricing?.Price,
          currency: data?.[0]?.Pricing?.Price?.Currency?.Code,
          user_email: data?.[0]?.User?.email,
          items: [
            {
              item_id: data?.[0]?.Pricing?.id,
              item_name: data?.[0]?.Pricing?.Title,
              price: data?.[0]?.Pricing?.Price,
              quantity: 1
            },
          ],
        });
      }
    } catch(e) {
      console.log('Unable to report purchase on Google Analytics.')
    }
  }, [data]);
  return (
    <>
      <div className="container ">
        <div className="container-custom">
          <div className="printer-top" />
          {isLoading ? (
            <PaymentSkeleton />
          ) : (
            <div className="paper-container">
              <div className="printer-bottom" />
              <div className="paper ">
                <div className="main-contents">
                  <div className="success-icon  bg-success">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <div className="success-title">Payment Successfull !!</div>
                  <div className="success-description ">
                    Thank you for your payment. Your transaction has been
                    completed !
                    <div className="bg-light mt-2">
                      <div className="text-dark ">Package : </div>
                      <div className="text-dark ">
                        {data?.[0]?.Pricing?.Title}
                      </div>
                    </div>
                  </div>
                  <div className="order-details">
                    <div className="order-number-label">Transaction Number</div>
                    <div className="order-number">{data?.[0]?.id}</div>
                  </div>
                  <div className="order-footer">Thank you!</div>
                </div>
                <div className="jagged-edge" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

PaymentSuccess.propTypes = propTypes;
PaymentSuccess.defaultProps = defaultProps;

export default PaymentSuccess;
