export const initialState = {
  currencyList: [],
  durationTypeList: [],
  employerTypeList: [],
  jobCategoryList: [],
  jobStatusList: [],
  jobTypeList: [],
  territoryList: [],
  workLocationList: [],
  couponCodeList: [],
  remoteAppConfig: [],
};
