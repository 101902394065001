/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../helpers/RouteHelper";
import { useTranslation } from "react-i18next";
import { TENANT_CONFIG } from "../../../config/tenant";
import { APP_CODE } from "../../../config/env";
import { VIDEO_LINK } from "../../../config/config";

const HowToVideos = () => {
  const { t } = useTranslation();

  return (
    <section className="pb-0 pb-xl-5" style={{ background: "var(--primary)" }}>
      <div className="container">
        <div className="row g-4 justify-content-between align-items-center">
          {/* Left side START */}
          <div className="col-md-6 position-relative">
            <h2 className="mb-3 mb-lg-5" style={{ color: "var(--primary-contrast)" }}>
              Steps to copy the job from JobBank!
            </h2>
            <iframe
              width="100%"
              height="300"
              src={VIDEO_LINK.STEPS_TO_COPY}
              title="Steps to copy the job from JobBank"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
          <div className="col-md-6 position-relative">
            <h2 className="mb-3 mb-lg-5" style={{ color: "var(--primary-contrast)" }}>
              Steps to migrate the Job to another portals!
            </h2>
            <iframe
              width="100%"
              height="300"
              src={VIDEO_LINK.STEPS_TO_MIGRATE}
              title="Steps to migrate the Job to another portals"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
          {/* Left side END */}
          {/* Right side START */}
        </div>
      </div>
    </section>
  );
};

export default HowToVideos;
