import store from "../store/store";

export const postReq = (
  url,
  values,
  addAuthorization = true,
  isFormData = false
) => {
  const state = store.getState();
  const myHeaders = new Headers();
  if (!isFormData) {
    myHeaders.append("Content-Type", "application/json");
  }
  if (addAuthorization) {
    myHeaders.append("Authorization", `Bearer ${state.app.AccessToken}`);
  }
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: isFormData ? values : JSON.stringify(values),
    // redirect: "follow",
  };
  return fetch(url, requestOptions);
};

export const putReq = (
  url,
  values,
  addAuthorization = true,
  isFormData = false
) => {
  const state = store.getState();
  const myHeaders = new Headers();
  if (!isFormData) {
    myHeaders.append("Content-Type", "application/json");
  }
  if (addAuthorization) {
    myHeaders.append("Authorization", `Bearer ${state.app.AccessToken}`);
  }
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: isFormData ? values : JSON.stringify(values),
    // redirect: "follow",
  };
  return fetch(url, requestOptions);
};
