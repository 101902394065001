import { APP_TYPE_CODES } from "./../helpers/typeCd";
import { APP_CODE } from "./env";
// import translationsEn from "../i18n/locales/newcomersjobshub/en/translation.json";

export const TENANT_CONFIG = {
  EnablePackage: import.meta.env.VITE_APP_ENABLE_PACKAGE === "true" ? true : false,
  AppCode: APP_CODE,
  AppTitle: import.meta.env.VITE_APP_TITLE,
  AppHost: import.meta.env.VITE_APP_HOST,
  AppEmail: import.meta.env.VITE_APP_EMAIL,
  LogoSquare: `/${APP_CODE}/Logo_Square.png`,
  Logo: `/${APP_CODE}/Logo.png`,
  EnableReCaptcha:
    import.meta.env.VITE_ENABLE_RECAPTCHA === "true" ? true : false,
  ReCaptchaToken: import.meta.env.VITE_RECAPTCHA_TOKEN,
  FiresBaseConfig: null,
  i18nResources: null,
};

const file = await import(`../i18n/${APP_CODE}/locales/en/translation.json`);
TENANT_CONFIG.i18nResources = { en: { translation: file.default } };
switch (APP_CODE) {
  case APP_TYPE_CODES.NEW_COMERS:
    TENANT_CONFIG.FiresBaseConfig = {
      apiKey: "AIzaSyBMjlT9tFxPcBJzdJ3V-_mm35ueh2_SOP4",
      authDomain: "newcomersjobshub.firebaseapp.com",
      projectId: "newcomersjobshub",
      storageBucket: "newcomersjobshub.appspot.com",
      messagingSenderId: "225602514101",
      appId: "1:225602514101:web:90449e02daeff7a63522fc",
      measurementId: "G-VVHJTVCB7L",
    };
    break;
  case APP_TYPE_CODES.INDIGENOUS:
    TENANT_CONFIG.FiresBaseConfig = {
      apiKey: "AIzaSyDuVO_MO8uxZY_G-zHUJQZzFn9cu0Vt9Qo",
      authDomain: "indigenouscareer.firebaseapp.com",
      projectId: "indigenouscareer",
      storageBucket: "indigenouscareer.appspot.com",
      messagingSenderId: "1078414732255",
      appId: "1:1078414732255:web:a8e39b4bd961af1760b26a",
      measurementId: "G-EXZJKMB0NL",
    };
    break;
  case APP_TYPE_CODES.VETERANS:
    TENANT_CONFIG.FiresBaseConfig = {
      apiKey: "AIzaSyDNlArQPK2FHjhuCdXWCCluu4l0e_25LOM",
      authDomain: "veteransseniorsjobs.firebaseapp.com",
      projectId: "veteransseniorsjobs",
      storageBucket: "veteransseniorsjobs.appspot.com",
      messagingSenderId: "1038550773155",
      appId: "1:1038550773155:web:49712a65f21a1730227e16",
      measurementId: "G-Z25S2LGK86",
    };
    break;
  case APP_TYPE_CODES.YOUTH_CAREER:
    TENANT_CONFIG.FiresBaseConfig = {
      apiKey: "AIzaSyBX2jRIidiTUSnnev-kdLo4tCKPHrBzuvg",
      authDomain: "youthcareer-87a49.firebaseapp.com",
      projectId: "youthcareer-87a49",
      storageBucket: "youthcareer-87a49.appspot.com",
      messagingSenderId: "774780191515",
      appId: "1:774780191515:web:dc9bb0d5a50b2fa950c9be",
      measurementId: "G-X7YQLDWTJ2",
    };
    break;
  case APP_TYPE_CODES.ALL_NEW_JOBS:
    TENANT_CONFIG.FiresBaseConfig = {
      apiKey: "AIzaSyAQqAfq6lCxiEdCovwVSM7crfQ5PPFe6DY",
      authDomain: "allnewjobs-ce5c8.firebaseapp.com",
      projectId: "allnewjobs-ce5c8",
      storageBucket: "allnewjobs-ce5c8.appspot.com",
      messagingSenderId: "888808160129",
      appId: "1:888808160129:web:58f0a89d136069d05ec680",
      measurementId: "G-N3H528QHB4",
    };
    break;
  case APP_TYPE_CODES.JOB_WOB:
    TENANT_CONFIG.FiresBaseConfig = {
      apiKey: "AIzaSyA1rWZuIPSjo5lUyJ5TDOJTcG-qyfOwE9U",
      authDomain: "jobwob-931d6.firebaseapp.com",
      projectId: "jobwob-931d6",
      storageBucket: "jobwob-931d6.appspot.com",
      messagingSenderId: "661495240478",
      appId: "1:661495240478:web:e0888f4173597ea0591b0f",
      measurementId: "G-JSGH0S0HXT"
    };
    break;
}
