import axios from "axios";
import { concat } from "lodash";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { API_URL } from "../config/env";
import { postReq, putReq } from "./api";
import { TENANT_CONFIG } from "../config/tenant";

const QUERY_LIMITS = 6;

export const useGetUserJobs = (filters = "") => {
  return useQuery(
    ["latest-job-list", filters],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/job/user/details?${filters}`,
        params: {},
      }),
    { select: (response) => response.data.results }
  );
};

export const useSearchJobs = (query = "") => {
  return useQuery(
    ["job-query-search", query],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/job/search?q=${query}`,
        params: {},
      }),
    { select: (response) => response.data.results, enabled: !!query }
  );
};

export const useGetJobsDashboard = (filters = "") => {
  return useQuery(
    ["get-job-details-list", filters],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/job/details?${filters}`,
        params: {},
      }),
    { select: (response) => response.data.results }
  );
};

export const useGetUserJobsInfinite = (
  filters = "",
  useDashboardApi = false
) => {
  const getHomeCategory = ({ next }) => {
    return axios({
      method: "get",
      url:
        next ||
        `${API_URL}/job/${
          useDashboardApi ? "" : "user/"
        }details?limit=${QUERY_LIMITS}&${filters}`,
    });
  };

  return useInfiniteQuery(
    ["latest-job-pagination", filters],
    ({ pageParam = { next: "" } }) => {
      return getHomeCategory(pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.next
          ? {
              next: lastPage?.data?.next,
            }
          : undefined;
      },
      keepPreviousData: true,
      select: (response) => {
        let shelfData = [];
        if (response?.pages) {
          for (const items of response.pages) {
            shelfData = concat(shelfData, items?.data?.results);
          }
        }
        return {
          list: shelfData,
          totalCount: response?.pages?.[0]?.data?.count || 0,
        };
      },
      // ⬇️ disabled as long as the filter is empty
      enabled: !!filters,
    }
  );
};

export const useJobPost = () => {
  return useMutation({
    mutationFn: (values) => {
      return values.get("id")
        ? putReq(
            `${API_URL}/job${
              TENANT_CONFIG.EnablePackage ? "" : "/free"
            }/${values.get("id")}`,
            values,
            true,
            true
          )
        : postReq(
            `${API_URL}/job${
              TENANT_CONFIG.EnablePackage ? "" : "/free"
            }/create`,
            values,
            true,
            true
          );
    },
  });
};
export const migrateJob = (values, isFreePortal = false) =>
  postReq(`${API_URL}/api/job${isFreePortal ? "/free" : ""}/migrate`, values);

export const useGetJobs = (filters = "") => {
  return useQuery(
    ["jobs-list-job-list", filters],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/job?${filters}`,
        params: {},
      }),
    { select: (response) => response.data.results }
  );
};

export const useGetJobById = (id) => {
  return useQuery(
    ["job-by-id", id],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/job/${id}`,
        params: {},
      }),
    { select: (response) => response.data }
  );
};
