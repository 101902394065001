/* eslint-disable react/prop-types */
import { Helmet } from "react-helmet";
import { TENANT_CONFIG } from "../config/tenant";
import { APP_CODE } from "../config/env";
import { APP_TYPE_CODES } from "./typeCd";

export const SEO_Base_Url = `https://${TENANT_CONFIG.AppHost}`;

export const SEO_Base_Title = TENANT_CONFIG.AppTitle;

const keywords =
  "indeed jobs, jobs near me, government of canada jobs, job bank, jobs, job bank canada, gc jobs, newcomers Jobs, job hub, New Comers hub, newcomers, canada newcomers, newcomers canada canada jobs";
const keywordsDesc = `Some areas we covered are ${keywords} etc.`;
const default_desc =
  `Welcome to our job portal, where simplicity, security, and effectiveness are our top priorities. We have designed our platform to provide you with a seamless and efficient experience in finding the right talent for your organization. ` +
  keywordsDesc;

export const SEO = {
  HOME: {
    title: SEO_Base_Title,
    description: default_desc,
    url: SEO_Base_Url,
    type: "website",
  },
  BLOG: {
    title: `${SEO_Base_Title} - Blogs`,
    description: default_desc,
  },
  ABOUT_US: {
    title: `${SEO_Base_Title} - About Us`,
    description:
      `Welcome to ${TENANT_CONFIG.AppTitle} - Your Gateway to Thriving Careers in Canada! Are you a newcomer or a new immigrant to Canada, looking for the perfect job that matches your skills and qualifications? Look no further! ${TENANT_CONFIG.AppTitle} is a dedicated job portal designed exclusively for newcomers like you.` +
      keywordsDesc,
  },
  CONTACT_US: {
    title: `${SEO_Base_Title} - Contact Us`,
    description: default_desc,
  },
  PACKAGE: {
    title: `${SEO_Base_Title} - Packages`,
    description:
      `Welcome to our job portal, where simplicity, security, and effectiveness are our top priorities. We have designed our platform to provide you with a seamless and efficient experience in finding the right talent for your organization. At our job portal, we offer a unique approach to job posting. With the purchase of a job credit, you gain access to an extended posting period. Each job you post will remain active for an impressive 365 DAYS OR 180 DAYS OR 31 DAYS, ensuring maximum exposure and visibility for your vacancies. Plus, our system automatically removes expired jobs, saving you the hassle of manual removal.` +
      keywordsDesc,
  },
  JOBS: {
    title: `${SEO_Base_Title} - Find Jobs`,
    description:
      "We understand that each newcomer brings unique skills and qualifications to the table. Our platform utilizes advanced algorithms to match your skills and experience with job postings that best suit your profile. This personalized approach increases your chances of finding a job that fits your capabilities and aspirations." +
      keywordsDesc,
  },
  PRIVACY_POLICY: {
    title: `${SEO_Base_Title} - Privacy Policy`,
    description:
      `Privacy Policy for ${TENANT_CONFIG.AppTitle} Website. At ${TENANT_CONFIG.AppTitle}, we are committed to protecting your privacy and safeguarding the personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our website. By accessing and using our website, you consent to the terms outlined in this Privacy Policy.` +
      keywordsDesc,
  },
  T_AND_C: {
    title: `${SEO_Base_Title} - TERMS & CONDITIONS`,
    description:
      `Terms and Conditions for ${TENANT_CONFIG.AppTitle}. Please read these Terms and Conditions carefully before using the ${TENANT_CONFIG.AppTitle} website (the "Website"). By accessing or using the Website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, please refrain from using the Website.` +
      keywordsDesc,
  },
  LOGIN: {
    title: `${SEO_Base_Title} - Login`,
  },
  REGISTER: {
    title: `${SEO_Base_Title} - Register`,
  },
  FORGOT_PASSWORD: {
    title: `${SEO_Base_Title} - Forgot Password`,
  },
};

export const SeoSection = ({ PAGE }) => {
  // Newcomers
  if (APP_CODE === APP_TYPE_CODES.NEW_COMERS) {
    return (
      <Helmet>
        <title>
          Cheap job post package in Canada | Best job portal for newcomers
          canada | Job Migration from Jobbank
        </title>
        <meta
          name="keywords"
          content="New Immigrant jobs in Canada,
    Fresher jobs in Canada,
    Best job posting site in Canada,
    Post your jobs online,
    Job Migration from Jobbank,
    LMAI Jobs canada,
    easy Job Posting,
    Automated Job Posting,
    Cheap job post package in Canada,
    Best job portal for newcomers canada,"
        />
        <meta
          name="description"
          content="Newcomers Jobs Hub is a dedicated job portal designed exclusively for newcomers like you. Our platform connects you with employers from all industries. "
        />
        <meta name="robots" content="index, follow, all" />
        <meta name="language" content="English" />
        <meta name="organization" content="Newcomers Jobs Hub" />
        <meta name="author" content="Newcomers Jobs Hub" />
        <meta name="identifier-url" content="https://newcomersjobshub.ca/" />
        <meta
          name="DC.Title"
          content="Cheap job post package in Canada | Best job portal for newcomers canada | Job Migration from Jobbank"
        />
        <meta
          name="DC.Description"
          content="Easing the way to provide newcomers to Canada with job search support and empower them to reach their goals."
        />
        <meta name="rating" content="General" />
        <meta name="distribution" content="Global" />
        <meta name="city" content="Canada" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://newcomersjobshub.ca/" />
        <meta property="og:site_name" content="https://newcomersjobshub.ca/" />
        <link rel="canonical" href="https://newcomersjobshub.ca/" />
      </Helmet>
    );
  }
  return (
    <Helmet>
      {PAGE.title && <title>{PAGE.title}</title>}
      {PAGE.url && <link rel="canonical" href={PAGE.url} />}
      <meta
        name="description"
        content={PAGE.description || default_desc}
      ></meta>
      {PAGE.title && <meta property="og:title" content={PAGE.title} />}
      {PAGE.type && <meta property="og:type" content={PAGE.type} />}
      {PAGE.url && (
        <meta property="og:url" content={PAGE.url || window.location.href} />
      )}

      <meta name="dcterms.language" content="eng" />
      <meta
        name="dcterms.creator"
        content="Employment and Social Development Canada"
      />
      <meta name="dcterms.service" content="ESDC-EDSC_JobBank-GuichetEmplois" />
      <meta name="dcterms.accessRights" content="2" />
      <meta name="dcterms.title" content={PAGE.title} />
      <meta property="og:image" content={TENANT_CONFIG.Logo}></meta>
      <meta name="keywords" content={keywords}></meta>
    </Helmet>
  );
};
