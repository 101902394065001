/* eslint-disable react/prop-types */
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import PageNotFound from "./pages/app/PageNotFound";
import HomePage from "./pages/home/HomePage";
import "./MainRouter.css";
import LoginPage from "./pages/login/SignInPage";
import RegisterPage from "./pages/login/RegisterPage";
import ForgotPassword from "./pages/login/ForgotPassword";
import { connect } from "react-redux";
import { ROUTES } from "../src/helpers/RouteHelper";
import PaymentSuccess from "./pages/payment/PaymentSuccess";
import PaymentFailure from "./pages/payment/PaymentFailure";
import PaymentPage from "./pages/payment/PaymentPage";
import { LOGIN_TYPE } from "./helpers/typeCd";
import { IsBackendSection } from "./config/config";

const JobDetailPage = React.lazy(() => import("./pages/jobs/JobDetailPage"));
const PackagePage = React.lazy(() => import("./pages/package/PackagePage"));
const PrivacyPolicyPage = React.lazy(() =>
  import("./pages/app/PrivacyPolicyPage")
);
const BlogListPage = React.lazy(() => import("./pages/blogs/BlogListPage"));
const BlogDetailsPage = React.lazy(() =>
  import("./pages/blogs/BlogDetailsPage")
);
const TermsAndConditions = React.lazy(() =>
  import("./pages/app/TermsAndConditions")
);
const DashboardProfile = React.lazy(() =>
  import("./pages/dashboard/components/DashboardProfile")
);
const DashboardJobList = React.lazy(() =>
  import("./pages/dashboard/components/DashboardJobList")
);
const DashboardJobAppliedList = React.lazy(() =>
  import("./pages/dashboard/components/DashboardJobAppliedList")
);
const JobsPage = React.lazy(() => import("./pages/jobs/JobsPage"));
const AboutUsPage = React.lazy(() => import("./pages/app/AboutUsPage"));
const ContactUsPage = React.lazy(() => import("./pages/app/ContactUsPage"));
const JobsPostPage = React.lazy(() => import("./pages/jobs/JobsPostPage"));

const defaultProps = {};

const propTypes = {};

const mapStateToProps = ({ app }) => ({
  isAuthenticated: app?.isAuthenticated,
  IsBackendUser: app?.User?.IsBackendUser,
});

const MainRouter = connect(
  mapStateToProps,
  null
)(({ isAuthenticated, IsBackendUser }) => {
  const PrivateRoute = ({ children, loginAs = "" }) => {
    const location = useLocation();
    return isAuthenticated ? (
      children
    ) : (
      <Navigate
        to={
          ROUTES.LOGIN +
          `?loginAs=${loginAs}&redirectTo=${
            location.pathname + location.search
          }`
        }
      />
    );
  };

  const closeSideBar = () => {
    try {
      let toggleBtn = document.getElementById("navbutton");
      let sidebarOpen = document.getElementById("navbarNavDropdown");
      toggleBtn.classList.remove("open");
      sidebarOpen.classList.remove("show");
    } catch {
      /* empty */
    }
  };

  return (
    <div className="route-body" onClick={() => closeSideBar()}>
      <Routes>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.JOBS} element={<JobsPage />} />
        {IsBackendSection() && (
          <Route
            path={ROUTES.JOBS_POST}
            element={
              <PrivateRoute loginAs={LOGIN_TYPE.EMPLOYER}>
                <JobsPostPage />
              </PrivateRoute>
            }
          />
        )}
        {IsBackendSection() && (
          <Route
            path={ROUTES.PAYMENT_PURCHASE}
            element={
              <PrivateRoute loginAs={LOGIN_TYPE.EMPLOYER}>
                <PaymentPage />
              </PrivateRoute>
            }
          />
        )}
        <Route path={ROUTES.JOBS_VIEW} element={<JobDetailPage />} />
        <Route path={ROUTES.PACKAGES} element={<PackagePage />} />
        <Route path={ROUTES.ABOUT_US} element={<AboutUsPage />} />
        <Route path={ROUTES.CONTACT_US} element={<ContactUsPage />} />
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
        <Route path={ROUTES.BLOG_LIST} element={<BlogListPage />} />
        <Route path={ROUTES.BLOG_DETAILS} element={<BlogDetailsPage />} />
        <Route
          path={ROUTES.TERM_AND_CONDITIONS}
          element={<TermsAndConditions />}
        />
        {isAuthenticated && IsBackendUser && (
          <>
            <Route
              path={ROUTES.DASHBOARD_JOB_LIST}
              element={
                <PrivateRoute loginAs={LOGIN_TYPE.EMPLOYER}>
                  <DashboardJobList />
                </PrivateRoute>
              }
            />
          </>
        )}
        {isAuthenticated && (
          <>
            <Route
              path={ROUTES.DASHBOARD_PROFILE}
              element={
                <PrivateRoute loginAs={LOGIN_TYPE.EMPLOYER}>
                  <DashboardProfile />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_JOB_APPLIED}
              element={
                <PrivateRoute loginAs={LOGIN_TYPE.CANDIDATE}>
                  <DashboardJobAppliedList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.PAYMENT_SUCCESS}
              element={
                <PrivateRoute loginAs={LOGIN_TYPE.EMPLOYER}>
                  <PaymentSuccess />
                </PrivateRoute>
              }
            />

            <Route
              path={ROUTES.PAYMENT_FAILURE}
              element={
                <PrivateRoute loginAs={LOGIN_TYPE.EMPLOYER}>
                  <PaymentFailure />
                </PrivateRoute>
              }
            />
          </>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
});

MainRouter.propTypes = propTypes;
MainRouter.defaultProps = defaultProps;
export default MainRouter;
