import React from 'react';

/**
 * It Catch JavaScript errors anywhere in their child component tree, log those errors,
 * and display a fallback UI instead of the component tree that crashed
 * Error Boundary is not supported with functional component yet
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    try {
      console.log('Error');
    } catch { /* empty */ }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h3>{this.state.error.message}</h3>
          {this.state.error.stack}
        </>
      );
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;
