/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useGetAllWallets, useGetCurrentWallet } from "./services/useWallet";
import { useGetTenants } from "./services/useTenant";
import {
  setTenants,
  setUserActiveWallet,
  setUserAllWallet,
} from "./store/reducers/appSlice/appSlice";
import VerifyEmail from "./pages/login/VerifyEmail";
const defaultProps = {};

const propTypes = {};

const mapStateToProps = ({ app }) => ({
  isAuthenticated: app?.isAuthenticated,
  IsBackendUser: app?.User?.IsBackendUser,
  IsEmailVerified: app?.User?.IsEmailVerified,
});

const AuthData = connect(
  mapStateToProps,
  null
)((IsEmailVerified) => {
  const dispatch = useDispatch();
  const refetchFlags = useSelector((state) => state.app.refetchFlags);

  const {
    data: walletData,
    refetch: refetchActiveWallet,
    isLoading: isLoadingActiveWallet,
  } = useGetCurrentWallet();

  const {
    data: allWalletsData,
    refetch: refetchAllWallet,
    isLoading: isLoadingAllWallet,
  } = useGetAllWallets();

  const {
    data: tenantData,
    refetch: refetchTenant,
    isLoading: isLoadingTenant,
  } = useGetTenants();

  useEffect(() => {
    refetchFlags.activeWallet && refetchActiveWallet();
    refetchFlags.activeWallet && refetchAllWallet();
    refetchFlags.tenants && refetchTenant();
  }, [refetchActiveWallet, refetchFlags, refetchAllWallet, refetchTenant]);

  useEffect(() => {
    if (walletData?.length > 0) {
      dispatch(setUserActiveWallet(walletData));
    }
  }, [dispatch, walletData]);

  useEffect(() => {
    if (allWalletsData?.length > 0) {
      dispatch(setUserAllWallet(allWalletsData));
    }
  }, [dispatch, allWalletsData]);

  useEffect(() => {
    if (tenantData?.length > 0) {
      dispatch(setTenants(tenantData));
    }
  }, [dispatch, tenantData]);

  return (
    <>
      {(isLoadingActiveWallet || isLoadingAllWallet || isLoadingTenant) && (
        <div className="loading" />
      )}
      {IsEmailVerified && <VerifyEmail />}
    </>
  );
});

AuthData.propTypes = propTypes;
AuthData.defaultProps = defaultProps;
export default AuthData;
