import { combineReducers } from "@reduxjs/toolkit";
import AppReducer from "./reducers/appSlice/appSlice";
import MasterReducer from "./reducers/masterSlice/masterSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const appPersistConfig = {
  key: "job-hub-app",
  storage,
  whitelist: [
    "UserId",
    "localization",
    "RefreshToken",
    "AccessToken",
  ],
};
const masterPersistConfig = {
  key: "job-hub-master",
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, AppReducer),
  master: persistReducer(masterPersistConfig, MasterReducer),
});

export default rootReducer;
