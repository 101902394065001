export const initialState = {
  /**
   * Default language of APP
   */

  isAuthenticated: false,

  locale: "en",
  RefreshToken: "",
  AccessToken: "",
  UserName: "",
  Email: "",
  UserId: "",
  User: {
    IsBackendUser: false,
    IsFrontendUser: false,
    LastLogin: false,
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    Permissions: [],
    Groups: [],
  },
  UserActiveWallet: null,
  IsPostingAllowed: null,
  refetchFlags: {
    activeWallet: false,
  },
  UserAllWallet: null,
  Tenants: [],
};
