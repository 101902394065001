import { toastError, toastSuccess } from "../../helpers/CommonHelper";
import { ROUTES } from "../../helpers/RouteHelper";
import { setUser } from "../../store/reducers/appSlice/appSlice";

export const onSuccess = async (response, setSubmitting, dispatch, redirectTo, navigate, setFieldError, setError) => {
  const data = await response.json();
  setSubmitting(false);
  if ([200, 201].includes(response?.status)) {
    toastSuccess("Successfully login!");
    dispatch(setUser(data));
    if (redirectTo) {
      if(data?.User?.is_frontend_user && redirectTo === ROUTES.JOBS_POST) {
        navigate(ROUTES.JOBS);
      }
      else {
        navigate(redirectTo);
      }
    }
  } else if (response?.status === 400) {
    Object.entries(data).forEach((err) => {
      if (Array.isArray(err[1])) {
        err[1].forEach((e) => {
          setFieldError(err[0], e);
        });
      } else {
        setFieldError(err[0], err[1]);
      }
    });
    setError(data?.non_field_errors?.[0])
  } else {
    toastError(data?.detail || "Retry Again, Some error occurred!");
  }
}