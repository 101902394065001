import store from "../store/store";
// will hide/show section of home page
export const HOME_PAGE = {
    featuredJobs: false,
    whyChooseUs: true,
    latestJobs: true,
    homeBanner: true,
    pricingSection:true,
    contactUs:true, 
}
export const FOOTER = {
    showNewsLetter: false
}
export const PRICING = {
    showPriceDuration: false
}

export const MIGRATE_JOB = {
    ACTIVE_WALLET_REQUIRED: 1
}

export const IsFrontendSection = () => {
    const state = store.getState();
    const { isAuthenticated, User } = state.app;
    return !isAuthenticated ||(isAuthenticated && User.IsFrontendUser);
}

export const IsBackendSection = () => {
    const state = store.getState();
    const { isAuthenticated, User } = state.app;
    return !isAuthenticated ||(isAuthenticated && User.IsBackendUser);
}

export const VIDEO_LINK = {
  STEPS_TO_MIGRATE: "https://www.youtube.com/embed/VA4X6jMyLhI?si=5r1cWyU6aq2aqF-S",
  STEPS_TO_COPY: "https://www.youtube.com/embed/6llw354-cD8?si=AEBX0nwuyZTINCX3",
};