import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TENANT_CONFIG } from "../config/tenant";

const resources = TENANT_CONFIG.i18nResources;

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    supportedLngs: ["en", "fr"],
    resources,
    fallbackLng: "en", // default language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
