import { ROUTES } from "../../helpers/RouteHelper";
import ResetPasswordForm from "../../components/Auth/ResetPasswordForm";
import { SEO, SeoSection } from "../../helpers/SEOHelper";
import { Link } from "react-router-dom";
import { APP_CODE } from "../../config/env";

const ForgotPassword = () => {
  return (
    <>
      <div className="container mb-5">
        <SeoSection PAGE={SEO.FORGOT_PASSWORD} />
        <section className="vh-xxl-100 pt-5">
          <div className="container h-100 d-flex px-0 px-sm-4">
            <div className="row justify-content-center align-items-center m-auto">
              <div className="col-12">
                <div className="bg-mode shadow rounded-3 overflow-hidden">
                  <div className="row g-0">
                    <div className="col-lg-6 d-md-flex align-items-center order-2 order-lg-1">
                      <div className="p-3 p-lg-5">
                        <img
                          className="img-fluid"
                          src="/forgot-pass.svg"
                          alt=""
                        />
                      </div>
                      <div className="vr opacity-1 d-none d-lg-block" />
                    </div>
                    <div className="col-lg-6 order-1">
                      <div className="p-4 p-sm-7">
                        <Link to={ROUTES.HOME}>
                          <img
                            style={{
                              width: "100px",
                            }}
                            className="mb-4 h-50px"
                            src={`/${APP_CODE}/Logo.svg`}
                            alt="logo"
                          />
                        </Link>
                        <h1 className="mb-2 h3">Forgot password?</h1>
                        <ResetPasswordForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgotPassword;
