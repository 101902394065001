/* eslint-disable no-unused-vars */
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { toastError, toastSuccess } from "../../helpers/CommonHelper";
import { useLogin } from "../../services/useAuth";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/reducers/appSlice/appSlice";
import { onSuccess } from "./AuthHelper";
import { ROUTES } from "../../helpers/RouteHelper";
import { Link } from "react-router-dom";

const defaultProps = {};

const propTypes = {
  redirectTo: PropTypes.string,
};

const SignInForm = ({ redirectTo }) => {
  const [hidePassword, setHidePassword] = useState(true);
  const [apiError, setApiError] = useState("");
  const { mutate, isLoading, isSuccess } = useLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={Yup.object({
        username: Yup.string().required("Email/Username is required"),
        password: Yup.string()
          .max(50, "Must be 50 characters or less")
          .required("Password is required"),
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        mutate(values, {
          onSuccess: async (response) => {
            onSuccess(
              response,
              setSubmitting,
              dispatch,
              redirectTo,
              navigate,
              setFieldError,
              setApiError
            );
            // eslint-disable-next-line no-undef
            gtag?.("event", "login", {
              method: "username_with_password"
            });
          },
          onError: (error) => {
            setSubmitting(false);
            toastError("Retry Again, Some error occurred!");
          },
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="mt-4 text-start">
          {/* <!-- Email --> */}
          <div className="mb-3">
            <label className="form-label">Enter email id</label>
            {/* <input type="email" className="form-control" /> */}
            <Field
              name="username"
              type="text"
              placeholder="Username/ Email"
              className="form-control"
            />
            {errors.username && touched.username ? (
              <div className="text-danger">{errors.username}</div>
            ) : null}
          </div>
          {/* <!-- Password --> */}
          <div className="mb-3 position-relative">
            <label className="form-label">Enter password</label>
            <Field
              name="password"
              placeholder={"Password"}
              type={hidePassword ? "password" : "text"}
              className="form-control"
              autoComplete="current-password"
              id="current-password"
            />
            <span
              className="position-absolute top-50 end-0 translate-middle-y p-0 mt-3"
              onClick={() => setHidePassword(!hidePassword)}
            >
              <i
                className={`fakepasswordicon fas fa-eye-slash cursor-pointer p-2 ${
                  hidePassword ? "" : "fa-eye"
                }`}
              />
            </span>
            {errors.password && touched.password ? (
              <span className="text-danger">{errors.password}</span>
            ) : null}
          </div>

          <div className="mb-3 d-sm-flex justify-content-between">
            {/* <div>
              <input
                type="checkbox"
                className="form-check-input"
                id="rememberCheck"
              />
              <label className="form-check-label" htmlFor="rememberCheck">
                Remember me?
              </label>
            </div> */}
            <Link disabled={isLoading} to={ROUTES.FORGOT_PASSWORD}>
              Forgot password?
            </Link>
          </div>

          <div>
            {apiError && <span className="text-danger">{apiError}</span>}
            <button
              type="submit"
              className="btn w-100 mb-0 button-animated"
              style={{ background: 'var(--secondary)', color: 'var(--secondary-contrast)' }}
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>
          </div>

          {false && (
            <>
              {/* Divider */}
              <div className="position-relative my-4">
                <hr />
                <p className="small bg-mode position-absolute top-50 start-50 translate-middle px-2">
                  Or sign in with
                </p>
              </div>
              {/* Google and facebook button */}
              <div className="vstack gap-3">
                <span className="btn btn-light mb-0">
                  <i className="fab fa-fw fa-google text-google-icon me-2" />
                  Sign in with Google
                </span>
                <span className="btn btn-light mb-0">
                  <i className="fab fa-fw fa-facebook-f text-facebook me-2" />
                  Sign in with Facebook
                </span>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

SignInForm.propTypes = propTypes;
SignInForm.defaultProps = defaultProps;

export default SignInForm;
