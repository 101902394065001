/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import ContactUsBanner from "./components/ContactUsBanner";
import HomeBanner from "./components/HomeBanner";
import JobSectionLoadMore from "../../components/Job/JobSectionLoadMore";
import { useEffect } from "react";
import HomeBannerTwo from "./components/HomeBannerTwo";
import PricingSection from "../../components/pricing/PricingSection";
import WhyChooseUs from "../../components/WhyChooseUs";
import {
  HOME_PAGE,
  IsBackendSection,
  IsFrontendSection,
} from "../../config/config";
import { connect } from "react-redux";
import { SEO, SeoSection } from "../../helpers/SEOHelper";
import HowToVideos from "./components/HowToVideos";
import { TENANT_CONFIG } from "../../config/tenant";

const defaultProps = {};

const propTypes = {};

const mapStateToProps = ({ app }) => ({
  isAuthenticated: app?.isAuthenticated,
  IsBackendUser: app?.User?.IsBackendUser,
  IsFrontendUser: app?.User?.IsFrontendUser,
});

const HomePage = connect(
  mapStateToProps,
  null
)(({ isAuthenticated, IsBackendUser, IsFrontendUser }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SeoSection PAGE={SEO.HOME} />

      {/* BANNER SECTION */}
      <div className="home-latest-job">
        <HomeBanner />
      </div>

      {/* FEATURED JOB SECTION */}
      {IsFrontendSection() && HOME_PAGE.featuredJobs && (
        <div className="home-latest-job bg-light pt-5">
          <div className="section-head text-center">
            <h2
              className="title wow fadeInUp"
              data-wow-delay="0.8s"
              style={{
                visibility: "visible",
                animationDelay: "0.8s",
                animationName: "fadeInUp",
              }}
            >
              Explore <strong>Featured</strong> Jobs{" "}
            </h2>
          </div>
          <JobSectionLoadMore
            filterQuery="IsFeatured=true&ordering=-CreatedAt"
            maxResultsShow={12}
          />
        </div>
      )}

      <HowToVideos />

      {/* LATEST JOB SECTION */}
      {HOME_PAGE.latestJobs && (
        <div className="home-latest-job bg-light pt-5 pb-5">
          <div className="section-head text-center">
            {/* <h6
              className="text wow fadeInUp"
              data-wow-delay="0.6s"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "fadeInUp",
              }}
            >
              Latest Jobs Post's
            </h6> */}
            <h2
              className="title wow fadeInUp"
              data-wow-delay="0.8s"
              style={{
                visibility: "visible",
                animationDelay: "0.8s",
                animationName: "fadeInUp",
              }}
            >
              Find Your Career You Deserve{" "}
            </h2>
          </div>
          <JobSectionLoadMore
            filterQuery="ordering=-CreatedAt"
            maxResultsShow={12}
          />
        </div>
      )}

      {HOME_PAGE.whyChooseUs && <WhyChooseUs />}

      {HOME_PAGE.homeBanner && (
        <div className="bg-light">
          <HomeBannerTwo />
        </div>
      )}

      {/* PRICING SECTION */}
      {IsBackendSection() && HOME_PAGE.pricingSection && TENANT_CONFIG.EnablePackage && (
        <div className="home-pricing pt-5 bg-light">
          <PricingSection showCardOne isHomePage />
        </div>
      )}

      {/* CONTACT US SECTION */}
      {HOME_PAGE.contactUs && (
        <div className="home-contact-us bg-light">
          <ContactUsBanner />
        </div>
      )}
    </>
  );
});

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;
export default HomePage;
