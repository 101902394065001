import { useEffect, useState } from "react";
import SignInForm from "../../components/Auth/SignInForm";
import { ROUTES } from "../../helpers/RouteHelper";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { scrollToTop } from "../../helpers/CommonHelper";
import { SEO, SeoSection } from "../../helpers/SEOHelper";
import { APP_CODE } from "../../config/env";

const defaultProps = {};

const propTypes = {
  redirectTo: PropTypes.string,
};

const mapStateToProps = ({ app }) => ({
  isAuthenticated: app?.isAuthenticated,
});

const SignInPage = connect(
  mapStateToProps,
  null
)(({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [redirectTo] = useState(
    () => searchParams.get("redirectTo") || ROUTES.HOME
  );
  const [loginAs] = useState(() => searchParams.get("loginAs"));
  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectTo);
    }
    scrollToTop();
  }, [isAuthenticated, redirectTo, navigate]);

  return (
    <div className="container">
      <SeoSection PAGE={SEO.LOGIN} />
      <section className="vh-xxl-100 pt-5">
        <div className="container h-100 d-flex px-0 px-sm-4">
          <div className="row justify-content-center align-items-center m-auto">
            <div className="col-12">
              <div className="bg-mode shadow rounded-3 overflow-hidden">
                <div className="row g-0">
                  {/* <!-- Vector Image --> */}
                  <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                    <div className="p-3 p-lg-5">
                      <img className="img-fluid" src="/signin.svg" alt="" />
                    </div>
                    {/* <!-- Divider --> */}
                    <div className="vr opacity-1 d-none d-lg-block"></div>
                  </div>

                  {/* <!-- Information --> */}
                  <div className="col-lg-6 order-1">
                    <div className="p-4 p-sm-7">
                      {/* <!-- Logo --> */}
                      <div className="row">
                        <Link to={ROUTES.HOME}>
                          <img
                            style={{
                              width: "100px",
                            }}
                            className="h-50px mb-4 d-flex p-2"
                            src={`/${APP_CODE}/Logo.svg`}
                            alt="logo"
                          />
                        </Link>
                      </div>
                      {/* <!-- Title --> */}
                      <h1 className="mb-2 h3">Welcome back</h1>
                      <p className="mb-0">
                        New here?
                        <Link
                          to={`${ROUTES.REGISTER}?loginAs=${loginAs}&redirectTo=${redirectTo}`}
                        >
                          {" "}
                          Create an account
                        </Link>
                      </p>
                      <SignInForm redirectTo={redirectTo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

SignInPage.propTypes = propTypes;
SignInPage.defaultProps = defaultProps;

export default SignInPage;
